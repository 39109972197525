<template>
    <div class="row control-bar col-md-12" :class="{'menu-responsive': show_col_12}">
        <label v-show="show_col_12" for="client" class="col-12 mt-3">Organizações</label>
        <MultiSelect v-model="selectedOrganizations" :options="organizations"
                    class="kt-margin-5-desktop auto-center max-size-select"
                    :class="{ 'col-12': show_col_12 }"
                    optionLabel="display_name"
                    dataKey="id"
                    :appendTo="tableDiv"
                    id="client"
                    :filter="true"
                    :selectionLimit="organization_selection_limit"
                    placeholder="Organização"
                    :bodyStyle="{'max-width': '100px'}"
                    :disabled="show_historical_data"
                    display="chip">
            <template #value="slotProps">
                <template v-if="slotProps.value && slotProps.value.length > 0">
                    <div v-for="value in slotProps.value" class="chip-multiselect">
                        <span style="margin-right: 5px">{{ getSelectValue(value, "name") }}</span>
                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedOrg(value)"></i>
                    </div>
                </template>
                <template v-else-if="isLoading">
                    Carregando organizações...
                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                </template>
                <template v-else>
                    Organizações
                </template>
            </template>
            <template #option="slotProps">
                <div>{{slotProps.option.display_name}}</div>
            </template>
        </MultiSelect>

        <label v-show="show_col_12" for="vehicle" class="col-12 mt-4">Veículos</label>        
        <MultiSelect v-if="!showCascade" v-model="selectedVehicles" :options="vehicles"
                    class="kt-margin-5-desktop auto-center max-size-select"
                    :class="{ 'col-12': show_col_12 }"
                    optionLabel="display_id"
                    dataKey="id"
                    :appendTo="tableDiv"
                    id="vehicle"
                    :showToggleAll="false"
                    :selectionLimit="vehicles_selection_limit"
                    :filter="true"
                    placeholder="Veículos"
                    display="chip"
                    :disabled="show_historical_data || !isAnyOrgSelected">
            <template #value="slotProps">
                <template v-if="slotProps.value && slotProps.value.length > 0">
                    <div v-for="value in slotProps.value" class="chip-multiselect">
                        <span style="margin-right: 5px">{{ getSelectValue(value, "display_id") }}</span>
                        <i class="pi pi-times-circle chip-icon" style="font-size: 14px" @click.stop="chipClickedVehicle(value)"></i>
                    </div>
                </template>
                <template v-else-if="isLoading">
                    Carregando veículos...
                    <ProgressSpinner style="height: 13px;width: 20px"></ProgressSpinner>
                </template>
                <template v-else>
                    Veículos
                </template>
            </template>

            <template #option="slotProps">
                <div>{{slotProps.option.display_id}}</div>
            </template>
        </MultiSelect>
    <AppCascadeMultiSelect 
        v-if="showCascade"
        ref="cascadeMulti" 
        style="margin: 5px 0px 0px 5px;"
        :show_historical_data="show_historical_data"
        :vehicles="vehicles" 
        :feature="feature"
        :show_panel="show_panel" 
        :selected_vehicles="selectedVehicles"
        @cleanSelectedVehicles="cleanSelected" 
        :selected_organizations="selectedOrganizations"/>


        <slot name="slot-one"></slot>

        <label v-show="show_col_12" for="date" class="col-12 mt-4">Período</label>
        <app-filter-date-time-control-bar v-if="!show_panel" :class="{'col-12 p-0 m-0 justify-content-center align-items-center': show_col_12, 
                                        'kt-margin-5-desktop auto-center': !show_col_12}"
                                        @handleFilter="validateDataPreRequest" :is_col_12="show_col_12"
                                        @dateSelected="dateSelected"
                                        :is_working_modes="is_working_modes"
                                        :is_any_org_selected="isAnyOrgSelected"
                                        :loading_historical_data="filter_button_is_loading"
                                        :start_date_open_date="datePickerStartDate"
                                        :end_date_open_date="datePickerEndDate"
                                        :disabled_dates="vehiclesDatesDisabled"
                                        :show_historical_data="show_historical_data"/>

        <slot name="slot-two"></slot>

    </div>
</template>

<script>

    import moment from "moment";
    import axios from "../../axios/axios-auth";
    import orderCustomFieldByNaturalOrder from "../../mixins/ArrayMixin";

    import Chips from 'primevue/chips';
    import Button from 'primevue/button';
    import Calendar from 'primevue/calendar'
    import MultiSelect from 'primevue/multiselect';
    import ProgressSpinner from 'primevue/progressspinner'

    import VehiclesService from '@/services/VehiclesService';
    import OrganizationsService from '@/services/OrganizationsService';

    import AppButton from "./AppButton";
    import AppFilterDateTimeControlBar from "./AppFilterDateTimeControlBar";
    import AppCascadeMultiSelect from "./AppCascadeMultiSelect";


    export default {
        mixins: [orderCustomFieldByNaturalOrder],
        mounted() {
            this.setupRangeDates();
            this.organizationsService = new OrganizationsService();
            this.vehiclesService = new VehiclesService();
            this.getOrganizations();
            this.setupRangeDates();
            this.showCascade = this.isScreenMobile;
            this.tableDiv = !this.isScreenMobile ? 'tableDiv' : '';
        },
        data() {
            return {
                organizationsService: null,
                organizations: [],
                selectedOrganizations: [],
                tableDiv: '',
                firstLoad: true,

                vehicles: [],
                vehiclesList: [],
                selectedVehicles: [],

                loading_historical_data: false,
                datePickerStartDate: moment().subtract(1, 'day').startOf('day').toDate(),
                datePickerEndDate: moment().subtract(1, 'day').endOf('day').toDate(),
                disabledDates: [],
                //map com a lista de maquinas/datas que foram solicitadas anteriormente
                vehiclesDates: new Map(),

                //array passado para o componente de calendário
                vehiclesDatesDisabled: [],

                endDate: moment().format("YYYY-MM-DD"),
                initialDate: moment("2018-01-01", "YYYY-MM-DD"),
                isLoading: false,

                rangeDates: [],

                storedChosenOptions: {},

                dateAlreadySelected: false,

                showCascade: false,
                show_col_12: this.is_col_12,
                MAXIMUM_FILTERED_DAYS: 300
            }
        },
        props: {
            filter_button_is_loading: {
                type: Boolean,
                required: true
            },
            //prop usada quando seleciona a maquina clicando no marker
            selectedVehiclesProp: {
                type: Array
            },
            show_historical_data: {
                type: Boolean,
                required: false,
                default: false
            },
            feature:{
                type: String,
                required: true
            },
            vehicles_selection_limit: {
                type: Number,
                required: false,
                default: 10
            },
            organization_selection_limit: {
                type: Number,
                required: false,
                default: null
            },
            load_filtered_options: {
                type: Boolean,
                required: false,
                default: true
            },
            save_filtered_options: {
                type: Boolean,
                required: false,
                default: true
            },
            show_panel: {
                type: Boolean,
                default: false
            },
            is_col_12: {
                type: Boolean,
                default: false
            },
            is_working_modes: {
                type: Boolean,
                default: false
            },
        },
        methods: {
            cleanSelected(){
                this.selectedVehicles = [];
                this.$refs.cascadeMulti.closeSubMenu();
            },
            chipClickedOrg(value) {
                const index = this.selectedOrganizations.indexOf(value);
                if (index > -1) {
                    this.selectedOrganizations.splice(index, 1);
                }
            },
            chipClickedVehicle(value) {
                const index = this.selectedVehicles.indexOf(value);
                if (index > -1) {
                    this.selectedVehicles.splice(index, 1);
                }
            },
            getSelectValue(value, field) {
                if (value[field].length > 3) {
                    return value[field].substring(0,3) + "...";
                }

                return value[field];
            },
            getOrganizations() {
                let vm = this;
                this.organizationsService.getAllV3(this.feature).then((results) => {
                        this.isLoading = true;
                        this.populateOrgsList(results);
                        // Ao alterar o this.selectedOrganizations é chamado o watch dele
                        this.selectedOrganizations = this.loadFilteredOptionsOrg();
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Operação falhou',
                            detail: 'Não foi possível buscar organizações',
                            life: 5000
                        });
                        console.log(error)
                    });
            },
            getVehicles() {
                let vm = this;
                const promises = [];
                this.selectedOrganizations.forEach(org => {
                    promises.push(this.vehiclesService.getVehiclesByOrgV3(org.id));
                });

                Promise.allSettled(promises)
                    .then(async (results) => {
                        if (results.map(obj => obj.status).includes("rejected")) {
                            vm.$toast.add({
                                severity: 'warn',
                                summary: 'Sem acesso',
                                detail: 'Você não tem acesso aos veículos de todos as organizações',
                                life: 5000
                            });
                        }
                        let vehicles = [];
                        results.forEach(result => {
                            if (result.status === 'rejected') {
                                return;
                            }
                            vehicles.push.apply(vehicles, result.value)
                        })
                        this.vehiclesList = this.orderCustomFieldByNaturalOrder(vehicles, "display_id");

                        // Pega os dados do localStorage na primeira vez que a página é carregada
                        if(this.firstLoad && this.feature != 'DASHBOARD'){
                            this.loadFilteredOptions();
                            this.firstLoad = false;
                        }

                        this.organizeValues();
                    })
                    .catch((error) => {
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Operação falhou',
                            detail: 'Não foi possível buscar veículos',
                            life: 5000
                        });
                        console.log(error)
                    }).finally(() => {
                    vm.isLoading = false;
                });
            },
            organizeValues(){
                this.vehicles = this.vehiclesList.filter(vehicle =>
                    this.selectedOrganizations.some(org => vehicle.organization_id === org.id)
                );

                let selectedVehiclesList = [];
                this.selectedVehicles.forEach(vehicle => {
                    if (this.selectedOrganizations.map(org => org.id).includes(vehicle.organization_id) &&
                        !selectedVehiclesList.includes(vehicle)) {
                        selectedVehiclesList.push(vehicle);
                    }
                })

                this.selectedVehicles = selectedVehiclesList;
            },
            loadFilteredOptionsOrg() {
                this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));
                if (!this.storedChosenOptions || this.feature === 'DASHBOARD') {
                    return [];
                }

                if(this.feature === 'COSTS'){
                    return [this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0]];
                }

                return this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id));
            },
            loadFilteredOptions() {

                if (!this.load_filtered_options) {
                    return;
                }

                this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

                const EMPTY_ARRAY = [];

                if (!this.storedChosenOptions || !this.storedChosenOptions.clients ||
                    !this.storedChosenOptions.vehicles) {
                    this.storedChosenOptions = {
                        clients: EMPTY_ARRAY, vehicles: EMPTY_ARRAY,
                        startDate: moment().subtract(1, 'day').startOf('day').toDate(),
                        endDate: moment().subtract(1, 'day').endOf('day').toDate()
                    };
                    return;
                } else if (this.storedChosenOptions['operators']) {
                    //Deletando versão antiga do filtro, que tinha operators
                    this.storedChosenOptions = {
                        clients: EMPTY_ARRAY, vehicles: EMPTY_ARRAY,
                        startDate: moment().subtract(1, 'day').startOf('day').toDate(),
                        endDate: moment().subtract(1, 'day').endOf('day').toDate()
                    };
                    return;
                }

                this.dateAlreadySelected = true;
                let filteredVehicles = this.storedChosenOptions['vehicles'].filter(a => this.vehiclesList.some(b => a.id === b.id));
                this.selectedVehicles = filteredVehicles.length > 10 ? filteredVehicles.slice(0, 10) : filteredVehicles;
            },
            populateOrgsList(orgs) {
                this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
            },
            validateDataPreRequest(dates) {
                let vm = this;
                let start = Date.parse(dates.start_date) / 1000;
                let end = (Date.parse(dates.end_date) / 1000) + 60;
                let calc = ((end - start) / 86400) * this.selectedVehicles.length;
                if(calc > this.MAXIMUM_FILTERED_DAYS){
                    vm.$toast.add({
                        severity: 'warn',
                        summary: 'Otimize a Pesquisa',
                        detail: 'Selecione um período de tempo mais curto ou reduza o número de veículos',
                        life: 5000
                    });
                    return;
                }

                this.saveChosenOptionsToLocalStorage(dates);
                this.$emit('datesAndFiltersHandler', dates, this.selectedOrganizations, this.selectedVehicles);
            },
            saveChosenOptionsToLocalStorage(dates) {
                if (!this.save_filtered_options) {
                    return;
                }

                this.storedChosenOptions['clients'] = this.selectedOrganizations;
                this.storedChosenOptions['vehicles'] = this.selectedVehicles;

                if (dates) {
                    this.storedChosenOptions['startDate'] = dates.start_date.toString();
                    this.storedChosenOptions['endDate'] = dates.end_date.toString();
                }

                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
            },
            setupRangeDates() {
                while (this.initialDate.isBefore(this.endDate, 'day')) {
                    this.rangeDates.push(moment(this.initialDate).format('YYYY-MM-DD'));
                    this.initialDate = this.initialDate.add(1, 'days');
                }
            },
            dateSelected() {
                this.dateAlreadySelected = true;
            },
            changeVehicles(vehicles) {
                if(this.show_panel){
                    return;
                }
                
                // limpou a seleção
                if (vehicles.length === 0) {
                    this.vehiclesDatesDisabled = [];
                }

                let found = false;
                const promises = [];
                let vm = this;

                //primeiro vamos ver se já temos essas datas
                for (let i = 0; i < vehicles.length; i++) {
                    if (this.vehiclesDates.has(vehicles[i].id)) {
                        found = true
                    } else {
                        promises.push(axios.get('/api/v1/vehicles/' + vehicles[i].id + '/track/datesById'))
                    }
                }

                Promise.all(promises)
                    .then(function (responses) {
                        responses.forEach(response => {
                            if (response.data && response.data.dates) {
                                vm.vehiclesDates.set(response.data.vehicle_id, vm.removeInvalidDates(response.data.dates));
                                vm.updateDatesDisabled();
                            }
                        })
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

                if (found) {
                    this.updateDatesDisabled();
                }
            },
            removeInvalidDates(dates) {
                let endDate = moment().format("YYYY-MM-DD");
                let initialDate = moment("2018-01-01", "YYYY-MM-DD");

                let validDates = [];

                //se alguma data for antes de initialDate e depois de endDate remove elas
                for (let i = 0; i < dates.length; i++) {
                    if (moment(dates[i], "YYYY-MM-DD").isSameOrAfter(initialDate, 'year') && moment(dates[i], "YYYY-MM-DD").isSameOrBefore(endDate, 'year')) {
                        validDates.push(dates[i]);
                    }
                }
                return validDates;
            },
            updateDatesDisabled() {

                let datesSet = new Set();
                let vm = this;

                this.selectedVehicles.forEach(function (vehicle) {
                    let dates = vm.vehiclesDates.get(vehicle.id)
                    if (dates) {
                        dates.forEach(function (date) {
                            datesSet.add(date);
                        })
                    }
                });

                let datesList = Array.from(datesSet);
                datesList = this.sort(datesList);

                let disabledDates = this.rangeDates.slice();

                //se a data veio no request remove ela das datas desabilitadas
                datesList.forEach(function (data) {
                    let index = disabledDates.indexOf(moment(data).format("YYYY-MM-DD"));
                    if (index > -1) {
                        disabledDates.splice(index, 1);
                    }
                });

                //formata as datas cfme o componente pede
                let temp_dates = [];
                disabledDates.forEach(function (data) {
                    temp_dates.push(moment(data, "YYYY-MM-DD")._d)
                })
                if (datesList && datesList.length > 1 && !this.dateAlreadySelected) {
                    this.datePickerStartDate = moment(datesList[1], "YYYY-MM-DD").startOf('day').toDate();
                    this.datePickerEndDate = moment(datesList[0], "YYYY-MM-DD").endOf('day').toDate();
                } else if (datesList && datesList.length > 0 && !this.dateAlreadySelected) {
                    this.datePickerStartDate = moment(datesList[0], "YYYY-MM-DD").startOf('day').toDate();
                    this.datePickerEndDate = moment(datesList[0], "YYYY-MM-DD").endOf('day').toDate();
                }
                this.vehiclesDatesDisabled = temp_dates;
            },
            sort(array) {
                let len = array.length;
                if (len < 2) {
                    return array;
                }
                let pivot = Math.ceil(len/ 2);
                return this.merge(this.sort(array.slice(0, pivot)), this.sort(array.slice(pivot)));
            },
            merge(left, right) {
                let result = [];
                while ((left.length > 0) && (right.length > 0)) {
                    if (left[0] > right[0]) {
                        result.push(left.shift());
                    } else {
                        result.push(right.shift());
                    }
                }
                result = result.concat(left, right);
                return result;
            },
        },
        watch: {
            selectedVehicles: function (val) {
                this.changeVehicles(val);
                this.$emit('selectedVehiclesChanged', val);
                this.saveChosenOptionsToLocalStorage();
            },
            vehicles: function (val) {
                this.$emit('vehiclesChanged', val);
            },
            selectedVehiclesProp: function (val) {
                this.selectedVehicles = val;
            },
            selectedOrganizations: function (val) {
                this.$emit('selectedOrganizationsChanged', val);
                this.getVehicles();
            }
        },
        computed: {
            isAnyOrgSelected: function() {
                return this.selectedOrganizations.length > 0;
            },
            isScreenMobile: function() {
                return window.screen.width > 1024;
            }
        },
        components: {
            MultiSelect, AppButton, Calendar, AppFilterDateTimeControlBar, Button, Chips, ProgressSpinner, AppCascadeMultiSelect
        }
    }
</script>

<style scoped lang="scss">

  @import "src/assets/styles/primevue";

  .auto-center {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .time-calendar {
    margin-bottom: 10px;
    outline: 5px solid white;
    outline-offset: -2px;
  }

  .max-size-select {
    width: 250px;
  }

  .chip-multiselect {
      display:inline-block;
      border-radius: 15px 15px 15px 15px;
      margin-right: 4px;
      background-color: #dee2e6;
      padding: 3px 8px;
      justify-content:center;
      align-items:center;
  }

  .chip-multiselect:hover {
      cursor: default;
  }

  .chip-icon:hover {
      cursor: pointer;
  }

  .loading-multiselect {
      display:inline-block;
      justify-content:center;
      align-items:center;
      padding: 0;
  }
  
  .menu-responsive{
    display: flex; 
    justify-content: center; 
    margin-left: 0px;
  }
</style>

<style>
@media(max-width: 1024px){
    .p-multiselect-item{
        width: 480px !important;
    }

    .p-multiselect-panel{
        width: 250px !important;
        overflow-x: scroll !important;
    }
}
</style>
import axios from '../axios/axios-auth'

export default class VehiclesService {

    getVehiclesByOrg(orgId) {
        return axios.get(`/api/v1/vehicles/organization/${orgId}`).then(response => response.data)
    }

    //TODO definir uma url melhor
    getHistoricalData(payload) {
        return axios.post('/api/v3/vehicles/data', payload).then(response => response.data)
    }

    getHistoricalDataDashboard(id, start, end) {
        return axios.get(`/events/v1/eventsData/vehicles/${id}/date/${start}/${end}`).then(response => response.data)
    }

    /**
     * V3
     **/

    getVehiclesByOrgV3(orgId) {
        return axios.get(`/api/v3/organizations/${orgId}/vehicles`).then(response => response.data)
    }

    patchV3(vehicleId, data) {
        return axios.patch(`/api/v3/vehicles/${vehicleId}`, data).then(response => response.data)
    }

    getVehicleDetails(vehicleId) {
        return axios.get(`/api/v3/vehicles/${vehicleId}`).then(response => response.data)
    }

    makeUnavailable(vid) {
        return axios.patch(`/api/v3/vehicles/${vid}/makeUnavailable`).then(response => response.data)
    }

    makeAvailable(vid) {
        return axios.patch(`/api/v3/vehicles/${vid}/makeAvailable`).then(response => response.data)
    }

}

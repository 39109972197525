<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <div class="kt-portlet__head-label">
                    <div class="row center">
                        <Dropdown v-model="selectedOrg" :options="organizations"
                                  optionLabel="display_name" placeholder="Organização"
                                  class="auto-center" :dataKey="'id'"
                                  :filter="true"
                                  @input="saveChosenOptionsToLocalStorage"
                                  style="width: 250px">
                            <template #option="slotProps" style="width: 500px">
                                <div v-if="loadingOrgs"> Carregando, aguarde</div>
                                <div style="padding-right: 20px" v-else-if="slotProps.option.name === 'ND'">
                                    -
                                </div>
                                <div style="padding-right: 20px" v-else>
                                    {{ slotProps.option.display_name }}
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__body no-padding">
                <div class="card data-table-width">
                    <DataTable :value="gridData" :paginator="true" :rows="50" :rowHover="true" :loading="loading" filterDisplay="row" :filters.sync="filters"
                        :sortOrder="-1" sortField="last_update_date" stripedRows
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        :rowsPerPageOptions="[10,25,50]"
                        :selection.sync="selectedVehicles"
                        responsiveLayout="scroll"
                        currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} Veículos"
                        class="p-datatable-striped">
                        <template #header>
                            <div style="text-align: right">
                                <Button icon="pi pi-external-link" label="Exportar" @click="exportCsv" style="font-size: 13px;" />
                            </div>
                        </template>
                        <template #empty>
                            Sem dados para exibir. Selecione uma organização.
                        </template>
                        <template #loading>
                            Carregando veículos, por favor aguarde
                        </template>
                        <Column field="last_contact" header="Ultimo Contato" :styles="{'min-width':'11rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{dateHourFormat(slotProps.data.last_contact)}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="location" header="Localização" :styles="{'min-width':'11rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.location}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="identification_number" header="Chassis" :styles="{'min-width':'11rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.identification_number}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="identification" header="Identificação" :styles="{'min-width':'12rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.identification}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="type" header="Tipo" :styles="{'min-width':'10rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.type}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="manufacturer" header="Fabricante" :styles="{'min-width':'10rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.manufacturer}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="model" header="Modelo" :styles="{'min-width':'10rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.model}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column field="hour_meter" header="Horímetro" :styles="{'min-width':'10rem'}" headerClass="headerClass"
                                :showFilterMenu="false" :showClearButton="false" bodyClass="bodyClass"
                                :sortable="true">
                            <template #body="slotProps">
                                <span>{{slotProps.data.hour_meter.toFixed(1)}}</span>
                            </template>
                            <template #filter="{filterModel,filterCallback}">
                                <InputText type="text" v-model="filterModel.value" @input="filterCallback()" class="p-column-filter"/>
                            </template>
                        </Column>
                        <Column :headerStyle="{width: '4rem', padding: '0px'}"
                                bodyClass="bodyClass"
                                :bodyStyle="{width: '4rem', padding: '0px'}">
                            <template #body="slotProps">
                                <Button icon="pi pi-pencil" class="p-button-rounded"
                                        :class="slotProps.data.unavailability_date ? 'p-button-secondary' : 'p-button-success'"
                                        style="margin-right: 5px"
                                        @click="handleEdit(slotProps.data)"/>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
    </div>
</div>
</template>

<script>
import AppTable from "../../common/AppTable";
import {mapGetters} from "vuex";
import {router} from "../../../router";
import maskMacAddress from "../../../mixins/StringsMaskMixin";
import maskCpfCnpj from "../../../mixins/StringsMaskMixin";
import dateHourFormat from "../../../mixins/DateMixin";
import VehiclesService from "../../../services/VehiclesService";
import {STATUS} from "../../../constants/status"
import AppButton from "../../common/AppButton";

import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button'
import appendPercent from '../../../mixins/StringsMixins';
import formatValue from '../../../mixins/ValuesFormat'
import filterValue from "../../../mixins/FilterFunctionsDataTables";
import OrganizationsService from "@/services/OrganizationsService";
import orderCustomFieldByNaturalOrder from "@/mixins/ArrayMixin";
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import {FilterMatchMode} from 'primevue/api';
import {FilterService} from 'primevue/api';
const DATE_FILTER = 'DATE FILTER';
const HOUR_METER_FILTER = 'HOUR METER FILTER';

export default {
    mixins: [maskMacAddress, dateHourFormat, maskCpfCnpj, appendPercent, formatValue, filterValue, orderCustomFieldByNaturalOrder, exportJsonToCsv],
    created() {
        this.organizationsService = new OrganizationsService();
        this.vehiclesService = new VehiclesService();
    },
    mounted: function () {
        this.getOrganizations();
        FilterService.register(DATE_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.dateHourFormat(value).includes(filter);
        });
        FilterService.register(HOUR_METER_FILTER, (value, filter) => {
            if (filter === undefined || filter === null || filter.trim() === '') {
                return true;
            }
            if (value === undefined || value === null) {
                return false;
            }
            return this.formatValue(value).includes(filter);
        });
    },
    data() {
        return {
            status_list: STATUS,
            selectedVehicles: [],
            vehiclesService: null,
            filters: {
                'last_contact': {value: null, matchMode: DATE_FILTER},
                'location': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'identification_number': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'identification': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'organization': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'type': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'manufacturer': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'model': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'hour_meter': {value: null, matchMode: HOUR_METER_FILTER}
            },
            loading: false,
            gridData: [],
            organizationsService: null,
            loadingOrgs: false,
            selectedOrg: null,
            organizations: [],
            storedChosenOptions: {}
        }
    },
    methods: {
        exportCsv(){
            let csvData = [];

            let tempData = JSON.parse(JSON.stringify(this.gridData));

            tempData.forEach(data => {
                data.last_contact = this.dateHourFormat(data.last_contact);
                csvData.push(data);
            });

            const CSV_FIELDS = ['last_contact', 'location', 'identification_number', 'identification', 'type', 'manufacturer', 'model', 'hour_meter'];
            const CSV_LABELS = ['Último Contato', 'Localização', 'Chassis', 'Identificação', 'Tipo', 'Fabricante', 'Modelo', 'Horímetro'];

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, "vehicles");
        },
        getOrganizations() {
            let vm = this;
            this.loadingOrgs = true;
            this.organizationsService.getAllV3('VEHICLES').then((results) => {
                this.populateOrgsList(results);
                this.loadFilteredOptions();
            }).catch((error) => {
                    vm.$toast.add({
                        severity: 'error',
                        summary: 'Operação falhou',
                        detail: 'Não foi possível buscar organizações',
                        life: 5000
                    });
                    console.log(error)
                }).finally(() => {
                this.loadingOrgs = false;
            })
        },
        populateOrgsList(orgs) {
            this.organizations = this.orderCustomFieldByNaturalOrder(orgs, "name");
        },
        getNumberOfSections(sectionsData) {
            if (!sectionsData || sectionsData.length === 0) {
                return '';
            }

            let numberOfSectionsText = '';
            sectionsData.forEach(section => {
                let numberOfInnerSections = 0;
                section.forEach(innerSection => {
                    numberOfInnerSections++;
                })
                numberOfSectionsText += numberOfInnerSections + ',';
            })
            return numberOfSectionsText.slice(0, -1);
        },
        handleEdit(value) {
            router.push({name: 'vehicles-edit', params: {id: value.id}});
        },
        loadFilteredOptions() {
            this.storedChosenOptions = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS));

            if (!this.storedChosenOptions || !this.storedChosenOptions.clients || this.storedChosenOptions.clients.length === 0) {
                this.storedChosenOptions = {};
                return;
            }

            this.selectedOrg = this.storedChosenOptions['clients'].filter(a => this.organizations.some(b => a.id === b.id))[0];
        },
        saveChosenOptionsToLocalStorage() {
            this.storedChosenOptions['clients'] = [this.selectedOrg];
            this.storedChosenOptions['vehicles'] = [];
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_FILTER_BAR_OPTIONS, JSON.stringify(this.storedChosenOptions));
        },
    },
    watch: {
        selectedOrg: function (val) {
            this.loading = true
            this.vehiclesService.getVehiclesByOrgV3(val.id)
                .then(data => {
                    data.forEach(data => {
                        data.location = data.city ? data.city + " (" + data.state + ')' : null
                        data.hour_meter = data.hour_meter === undefined ? 0 : data.hour_meter;
                    })
                    this.gridData = data;
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Você não tem acesso a esssa funcionalidade',
                                life: 5000
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Não foi possivel completar a operação, tente novamente',
                                life: 5000
                            });
                        }
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação, tente novamente',
                            life: 5000
                        });
                    }

                }).finally(() => {
                    this.loading = false;
                }
            );
        }
    },
    components: {
        AppTable,
        DataTable,
        Column,
        InputText,
        MultiSelect,
        Calendar,
        Dropdown,
        ProgressBar,
        Button,
        AppButton,
        Dialog
    },
    computed: {
        ...mapGetters([
            'getCurrentOrganization'
        ]),
    },

}
</script>

<style lang="scss">
@import "./src/assets/styles/primevue";

.font-header{
font-size: 14px;
}

@media (max-width: 1024px){
.data-table-width{
    width: 100vw;
}
}

@media (min-width: 1025px){
.data-table-width{
    max-width: calc(100vw - 140px);
}
}
</style>

<i18n>
{
"pt-BR": {
"vehicles": "Veículos"
},
"en-US": {
"vehicles": "Vehicles"
}
}
</i18n>

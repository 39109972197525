<template>
    <div class="container-fluid">
        <div class="row">
            <app-vehicles-select :charts_types_data="selectOne"
                                 @handleSelectChanges="handleSelectChangesChangeOne"
                                 :selected="chart_one_selected"/>
        </div>
        <div class="row" v-show="chart_one_selected.key === 'events_timeline'">
            <app-vehicles-timeline-cards
                :eventsReport="eventsReport" :report_loading="reportLoading"
                v-bind:class="{'cards-timeline': (eventsReport.length > 0 && chart_one_selected.key === 'events_timeline'),
                                                            'chart': (eventsReport.length === 0 && chart_one_selected.key === 'events_timeline')}"
                @mouseOverEvent="mouseOverEvent"/>

        </div>
        <div class="row" v-show="chart_one_selected.key !== 'events_timeline'">
            <div class="container-fluid">
                <div class="row">
                    <app-vehicles-track-charts
                        class="chart"
                        :chart-data="chartOneData"
                        :seriesName="chart_one_selected.name"
                        :seriesUnit="chart_one_selected.unit"
                        v-show="chart_one_selected.key !== 'atividade' && chart_one_selected.key !== 'gasto_combustivel'
                                                                  && chart_one_selected.key !== 'events_timeline'  && chart_one_selected.key !== 'events_summary'"
                        @customMouseOver="customMouseOver">
                    </app-vehicles-track-charts>

                    <app-vehicles-track-charts-pie
                        :chart-data="chartOneData"
                        class="chart-pie"
                        :unit="chart_one_selected.key === 'atividade' ? 'horas' : 'L'"
                        v-show="chart_one_selected.key === 'atividade' || chart_one_selected.key === 'gasto_combustivel'">

                    </app-vehicles-track-charts-pie>

                    <app-vehicles-data-table
                        v-show="chart_one_selected.key === 'events_summary'"
                        :report_clustered="eventsReportClustered"
                        :report_clustered_loading="reportLoading"
                        :report="eventsReport"
                        v-bind:class="{'chart-pie': (eventsReportClustered.length > 0),'chart': ((eventsReportClustered.length === 0))}"/>

                </div>
            </div>

            <!-- Segundo Select -->
            <div class="container-fluid">
                <div class="row" v-show="(chart_one_selected.key !== 'atividade' && chart_one_selected.key !== 'gasto_combustivel' &&
                                        chart_one_selected.key !== 'events_summary' )">
                    <app-vehicles-select
                        :charts_types_data="this.charts_types_data.slice(4)"
                        @handleSelectChanges="handleSelectChangesChangeTwo"
                        :selected="chart_two_selected"/>

                    <app-vehicles-track-charts
                        class="chart"
                        :chart-data="chartTwoData"
                        :seriesName="chart_two_selected.name"
                        :seriesUnit="chart_two_selected.unit"
                        @customMouseOver="customMouseOver">
                    </app-vehicles-track-charts>

                </div>
            </div>

            <!-- Terceiro Select -->

            <div class="container-fluid">
                <div class="row"
                     v-show="(chart_one_selected.key !== 'events_timeline' || (chart_one_selected.key === 'events_timeline' && eventsReport.length === 0))">
                    <app-vehicles-select
                        :charts_types_data="selectThree"
                        @handleSelectChanges="handleSelectChangesChangeThree"
                        :selected="chart_three_selected"/>

                    <app-vehicles-track-charts
                        class="chart"
                        :chart-data="chartThreeData"
                        :seriesName="chart_three_selected.name"
                        :seriesUnit="chart_three_selected.unit"
                        v-show="chart_three_selected.key !== 'atividade' && chart_three_selected.key !== 'gasto_combustivel' &&
                                                                                   chart_three_selected.key !== 'events_summary'"
                        @customMouseOver="customMouseOver">
                    </app-vehicles-track-charts>

                    <app-vehicles-track-charts-pie
                        :chart-data="chartThreeData"
                        class="chart-pie"
                        :unit="chart_one_selected.key === 'atividade' ? 'horas' : 'L'"
                        v-show="chart_three_selected.key === 'atividade' || chart_three_selected.key === 'gasto_combustivel'"
                        @customMouseOver="customMouseOver">

                    </app-vehicles-track-charts-pie>

                    <app-vehicles-data-table
                        v-show="chart_three_selected.key === 'events_summary'"
                        :report_clustered="eventsReportClustered"
                        :report_clustered_loading="reportLoading"
                        :report="eventsReport"
                        v-bind:class="{'chart-pie': (eventsReportClustered.length > 0),'chart': ((eventsReportClustered.length === 0))}"/>
                </div>
            </div>

            <!-- Quarto Select -->
            <div class="container-fluid">
                <div class="row" v-show="
                                        (chart_three_selected.key !== 'atividade' && chart_three_selected.key !== 'gasto_combustivel' &&
                                        chart_three_selected.key !== 'events_summary' )">
                    <app-vehicles-select
                        :charts_types_data="this.charts_types_data.slice(4)"
                        @handleSelectChanges="handleSelectChangesChangeFour"
                        :selected="chart_four_selected">
                    </app-vehicles-select>

                    <app-vehicles-track-charts
                        class="chart"
                        :chart-data="chartFourData"
                        :seriesName="chart_four_selected.name"
                        :seriesUnit="chart_four_selected.unit"
                        @customMouseOver="customMouseOver">
                    </app-vehicles-track-charts>

                </div>
            </div>
        </div>
    </div>

</template>

<script>

import AppVehiclesSelect from "./AppVehiclesSelectCharts";
import AppVehiclesSelectVehicles from "./AppVehiclesSelectVehicles";
import AppVehiclesTimelineCards from "./AppVehiclesTimelineCards";
import AppVehiclesDataTable from "./AppVehiclesDataTable";
import AppVehiclesTrackCharts from "./AppVehiclesTrackCharts";
import AppVehiclesTrackChartsPie from "./AppVehiclesTrackChartsPie";
import moment from 'moment';

import {CHARTS_TYPES_DATA} from './AppOptions';

export default {

    mounted() {
        this.initializeChosenCharts();
        this.auxChartsTypesData = this.charts_types_data.slice(4);
    },

    data() {
        return {
            //variaveis para acompanhar a troca de valores no select
            chart_one_selected: {},
            chart_two_selected: {},
            chart_three_selected: {},
            chart_four_selected: {},
            charts_types_data: CHARTS_TYPES_DATA,
            auxChartsTypesData: [],

            chartOneData: [],
            chartTwoData: [],
            chartThreeData: [],
            chartFourData: [],

            altitude_series: [],
            number_of_satellites_series: [],
            positioning_precision_series: [],
            actual_engine_percent_torque_series: [],
            vehicle_slip_percentage_series: [],
            engine_speed_series: [],
            selected_gear_series: [],
            gps_vehicle_speed_series: [],
            device_temperature_series: [],
            vehicle_rpm_series: [],
            gsm_signal_series: [],
            gsm_band_series: [],
            engine_instantaneous_fuel_economy_series: [],
            engine_oil_level_series: [],
            fuel_level_series: [],

            atividade_series: [],
            gasto_combustivel_series: [],

            chart_one_selected_real_time: {},
            chart_two_selected_real_time: {},
            chart_three_selected_real_time: {},
            chart_four_selected_real_time: {},

        }
    },
    props: {
        selectedVehicles: {
            type: Array,
            default() {
                return []
            }
        },
        eventsReport: {
            default() {
                return []
            }
        },
        reportLoading: {
            type: Boolean,
            default: false
        },
        realtimeChartsData: {
            default() {
                return []
            }
        },
        eventsReportClustered: {
            default() {
                return []
            }
        },
        showHistoricalData: {
            type: Boolean,
            default: false
        },
        vehicles: {
            type: Array,
            default() {
                return []
            }
        },
        itemsCharts: {
            type: Number,
            default: 50
        },
        historicalChartsData: {
            type: Array,
            default() {
                return []
            }
        },
        historicalPieChartsData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        realtimeChartsData: function (val) {
            this.populeRealTimeCharts(val);
        },
        historicalChartsData: function (val) {

            this.clearChartsData();

            setTimeout(() => {
                this.populeHistoricalCharts(val);
            }, 50);

        },
        historicalPieChartsData: {
            handler(val) {
                this.handlePieChartsData(val);
            },
            deep: true
        },
    },
    methods: {
        customMouseOver(values) {
            this.$emit('customMouseOver', values)
        },
        mouseOverEvent(event) {
            this.$emit('mouseOverEvent', event)
        },
        handlePieChartsData(val) {
            this.gasto_combustivel_series = val.gasto_combustivel_series;
            this.atividade_series = val.atividade_series;
        },
        populeRealTimeCharts(val) {

            if (!val || val.length === 0) {
                this.clearChartsData();
                return;
            }

            if (!this.selectedVehicles || this.selectedVehicles.length === 0) {
                return;
            }

            for (const data of val) {

                let timestamp = Number(data._005) * 1000;

                if (moment().diff(moment(timestamp), 'hours') > 1) {
                    continue;
                }

                if (this.selectedVehicles.findIndex(v => v.id === data.id) < 0) {
                    continue;
                }

                if (!isNaN(data._011)) {

                    let index = this.altitude_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.altitude_series[index].data, timestamp)) {
                            this.altitude_series[index].data.push([timestamp, this.roundNumber(data._011)]);
                            this.purgeSeries(this.altitude_series[index].data);
                        }
                    } else {
                        this.altitude_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._011)]]
                        });
                    }
                }

                if (!isNaN(data._772)) {

                    let index = this.number_of_satellites_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.number_of_satellites_series[index].data, timestamp)) {
                            this.number_of_satellites_series[index].data.push([timestamp, this.roundNumber(data._772)]);
                            this.purgeSeries(this.number_of_satellites_series[index].data);
                        }
                    } else {
                        this.number_of_satellites_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._772)]]
                        });
                    }
                }

                if (!isNaN(data._008)) {

                    let index = this.positioning_precision_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.positioning_precision_series[index].data, timestamp)) {
                            this.positioning_precision_series[index].data.push([timestamp, this.roundNumber(data._008)]);
                            this.purgeSeries(this.positioning_precision_series[index].data);
                        }
                    } else {
                        this.positioning_precision_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._008)]]
                        });
                    }
                }
                if (!isNaN(data._060)) {

                    let index = this.actual_engine_percent_torque_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.actual_engine_percent_torque_series[index].data, timestamp)) {
                            this.actual_engine_percent_torque_series[index].data.push([timestamp, this.roundNumber(data._060)]);
                            this.purgeSeries(this.actual_engine_percent_torque_series[index].data);
                        }
                    } else {
                        this.actual_engine_percent_torque_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._060)]]
                        });
                    }
                }
                if (!isNaN(data._111)) {

                    let index = this.vehicle_slip_percentage_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.vehicle_slip_percentage_series[index].data, timestamp)) {
                            this.vehicle_slip_percentage_series[index].data.push([timestamp, this.roundNumber(data._111)]);
                            this.purgeSeries(this.vehicle_slip_percentage_series[index].data);
                        }
                    } else {
                        this.vehicle_slip_percentage_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._111)]]
                        });
                    }
                }
                if (!isNaN(data._059)) {

                    let index = this.vehicle_rpm_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.vehicle_rpm_series[index].data, timestamp)) {
                            this.vehicle_rpm_series[index].data.push([timestamp, this.roundNumber(data._059)]);
                            this.purgeSeries(this.vehicle_rpm_series[index].data);
                        }
                    } else {
                        this.vehicle_rpm_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._059)]]
                        });
                    }
                }

                if (!isNaN(data._076)) {

                    let index = this.engine_instantaneous_fuel_economy_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.engine_instantaneous_fuel_economy_series[index].data, timestamp)) {
                            this.engine_instantaneous_fuel_economy_series[index].data.push([timestamp, this.roundNumber(data._076)]);
                            this.purgeSeries(this.engine_instantaneous_fuel_economy_series[index].data);
                        }
                    } else {
                        this.engine_instantaneous_fuel_economy_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._076)]]
                        });
                    }
                }
                if (!isNaN(data._118)) {

                    let index = this.selected_gear_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.selected_gear_series[index].data, timestamp)) {
                            this.selected_gear_series[index].data.push([timestamp, this.roundNumber(data._118)]);
                            this.purgeSeries(this.selected_gear_series[index].data);
                        }
                    } else {
                        this.selected_gear_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._118)]]
                        });
                    }
                }
                if (!isNaN(data._012)) {

                    let index = this.gps_vehicle_speed_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.gps_vehicle_speed_series[index].data, timestamp)) {
                            this.gps_vehicle_speed_series[index].data.push([timestamp, this.roundNumber(data._012)]);
                            this.purgeSeries(this.gps_vehicle_speed_series[index].data);
                        }
                    } else {
                        this.gps_vehicle_speed_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._012)]]
                        });
                    }
                }
                if (!isNaN(data._769)) {

                    let index = this.device_temperature_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        if (!this.isDataDuplicated(this.device_temperature_series[index].data, timestamp)) {
                            this.device_temperature_series[index].data.push([timestamp, this.roundNumber(data._769)]);
                            this.purgeSeries(this.device_temperature_series[index].data);
                        }
                    } else {
                        this.device_temperature_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._769)]]
                        });
                    }
                }
                if (!isNaN(data._828)) {

                    let index = this.gsm_signal_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        //se vir o mesmo ts da ultima posição ignora
                        if (!this.isDataDuplicated(this.gsm_signal_series[index].data, timestamp)) {
                            this.gsm_signal_series[index].data.push([timestamp, this.roundNumber(data._828)]);
                            this.purgeSeries(this.gsm_signal_series[index].data);
                        }
                    } else {
                        this.gsm_signal_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._828)]]
                        });
                    }
                }
                if (!isNaN(data._058)) {

                    let index = this.fuel_level_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        //se vir o mesmo ts da ultima posição ignora
                        if (!this.isDataDuplicated(this.fuel_level_series[index].data, timestamp)) {
                            this.fuel_level_series[index].data.push([timestamp, this.roundNumber(data._058)]);
                            this.purgeSeries(this.fuel_level_series[index].data);
                        }
                    } else {
                        this.fuel_level_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._058)]]
                        });
                    }
                }
                if (!isNaN(data._061)) {

                    let index = this.engine_oil_level_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        //se vir o mesmo ts da ultima posição ignora
                        if (!this.isDataDuplicated(this.engine_oil_level_series[index].data, timestamp)) {
                            this.engine_oil_level_series[index].data.push([timestamp, this.roundNumber(data._061)]);
                            this.purgeSeries(this.engine_oil_level_series[index].data);
                        }
                    } else {
                        this.engine_oil_level_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._061)]]
                        });
                    }
                }
                if (!isNaN(data._777)) {

                    let index = this.gsm_band_series.findIndex(i => i.id === data.id);

                    if (index >= 0) {
                        //se vir o mesmo ts da ultima posição ignora
                        if (!this.isDataDuplicated(this.gsm_band_series[index].data, timestamp)) {
                            this.gsm_band_series[index].data.push([timestamp, this.roundNumber(data._777)]);
                            this.purgeSeries(this.gsm_band_series[index].data);
                        }
                    } else {
                        this.gsm_band_series.push({
                            id: data.id,
                            name: this.getVehicleDisplayNameByID(data.id),
                            type: 'line',
                            data: [[timestamp, this.roundNumber(data._777)]]
                        });
                    }
                }

            }

            this.setDataToCharts();
        },
        saveStateChartsRealTime() {
            this.chart_one_selected_real_time = this.chart_one_selected;
            this.chart_two_selected_real_time = this.chart_two_selected;
            this.chart_three_selected_real_time = this.chart_three_selected;
            this.chart_four_selected_real_time = this.chart_four_selected;
        },
        restoreStateChartsRealTime() {
            this.chart_one_selected = this.chart_one_selected_real_time;
            this.chart_two_selected = this.chart_two_selected_real_time;
            this.chart_three_selected = this.chart_three_selected_real_time;
            this.chart_four_selected = this.chart_four_selected_real_time;
        },

        populeHistoricalCharts(val) {
            for (const vehicleData of val) {

                for (const data of vehicleData.data) {

                    //ajuste tecnico para perder a precisão de milisegundos
                    let timestamp = (Number(data.ts) / 1000) * 1000;

                    if (!isNaN(data.s_580)) {

                        let index = this.altitude_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.altitude_series[index].data.push([timestamp, this.roundNumber(data.s_580)]);
                        } else {
                            this.altitude_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_580)]]
                            });
                        }
                    }

                    if (!isNaN(data.s_1000002)) {

                        let index = this.number_of_satellites_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.number_of_satellites_series[index].data.push([timestamp, this.roundNumber(data.s_1000002)]);
                        } else {
                            this.number_of_satellites_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_1000002)]]
                            });
                        }
                    }

                    if (!isNaN(data.s_1000003)) {

                        let index = this.positioning_precision_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.positioning_precision_series[index].data.push([timestamp, this.roundNumber(data.s_1000003)]);
                        } else {
                            this.positioning_precision_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_1000003)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_513)) {

                        let index = this.actual_engine_percent_torque_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.actual_engine_percent_torque_series[index].data.push([timestamp, this.roundNumber(data.s_513)]);
                        } else {
                            this.actual_engine_percent_torque_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_513)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_1030000)) {

                        let index = this.vehicle_slip_percentage_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.vehicle_slip_percentage_series[index].data.push([timestamp, this.roundNumber(data.s_1030000)]);
                        } else {
                            this.vehicle_slip_percentage_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_1030000)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_190)) {

                        let index = this.vehicle_rpm_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.vehicle_rpm_series[index].data.push([timestamp, this.roundNumber(data.s_190)]);
                        } else {
                            this.vehicle_rpm_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_190)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_533)) {

                        let index = this.selected_gear_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.selected_gear_series[index].data.push([timestamp, this.roundNumber(data.s_533)]);
                        } else {
                            this.selected_gear_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_533)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_84)) {

                        let index = this.gps_vehicle_speed_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.gps_vehicle_speed_series[index].data.push([timestamp, this.roundNumber(data.s_84)]);
                        } else {
                            this.gps_vehicle_speed_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_84)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_110)) {

                        let index = this.device_temperature_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.device_temperature_series[index].data.push([timestamp, this.roundNumber(data.s_110)]);
                        } else {
                            this.device_temperature_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_110)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_183)) {

                        let index = this.engine_instantaneous_fuel_economy_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.engine_instantaneous_fuel_economy_series[index].data.push([timestamp, this.roundNumber(data.s_183)]);
                        } else {
                            this.engine_instantaneous_fuel_economy_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_183)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_1000006)) {

                        let index = this.gsm_signal_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.gsm_signal_series[index].data.push([timestamp, this.roundNumber(data.s_1000006)]);
                        } else {
                            this.gsm_signal_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_1000006)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_1000010)) {

                        let index = this.gsm_band_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.gsm_band_series[index].data.push([timestamp, this.roundNumber(data.s_1000010)]);
                        } else {
                            this.gsm_band_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_1000010)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_98)) {

                        let index = this.engine_oil_level_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.engine_oil_level_series[index].data.push([timestamp, this.roundNumber(data.s_98)]);
                        } else {
                            this.engine_oil_level_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_98)]]
                            });
                        }
                    }
                    if (!isNaN(data.s_96)) {

                        let index = this.fuel_level_series.findIndex(i => i.id === vehicleData.id);

                        if (index >= 0) {
                            this.fuel_level_series[index].data.push([timestamp, this.roundNumber(data.s_96)]);
                        } else {
                            this.fuel_level_series.push({
                                id: vehicleData.id,
                                name: vehicleData.fleet,
                                type: 'line',
                                data: [[timestamp, this.roundNumber(data.s_96)]]
                            });
                        }
                    }
                }
            }
            this.setDataToCharts();
        },
        roundNumber(val, digits) {
            if (!digits) {
                digits = 2;
            }

            if (typeof (val) === 'string') {
                try {
                    val = Number(val)
                } catch (e) {
                    return val;
                }
            }

            try {
                return Number(val.toFixed(digits));
            } catch (e) {
                return val;
            }
        },

        /**
         * Considera duplicado se o ts do ultimo item do array for igual ao novo
         *
         * */
        isDataDuplicated(list, newData) {
            if (!list || list.length === 0) {
                return false;
            }
            return list[list.length - 1][0] === newData
        },
        clearChartsData() {
            this.chartOneData = [];
            this.chartTwoData = [];
            this.chartThreeData = [];
            this.chartFourData = [];

            this.altitude_series = [];
            this.number_of_satellites_series = [];
            this.positioning_precision_series = [];
            this.actual_engine_percent_torque_series = [];
            this.vehicle_slip_percentage_series = [];
            this.engine_speed_series = [];
            this.engine_instantaneous_fuel_economy_series = [];
            this.selected_gear_series = [];
            this.gps_vehicle_speed_series = [];
            this.device_temperature_series = [];
            this.vehicle_rpm_series = [];
            this.gsm_signal_series = [];
            this.gsm_band_series = [];
            this.engine_oil_level_series = [];
            this.fuel_level_series = [];
        },
        clearPieChartsData() {
            this.atividade_series = [];
            this.gasto_combustivel_series = [];
        },
        setDataToCharts() {
            this.chartOneData = this.getChartData(this.chart_one_selected);
            this.chartTwoData = this.getChartData(this.chart_two_selected);
            this.chartThreeData = this.getChartData(this.chart_three_selected);
            this.chartFourData = this.getChartData(this.chart_four_selected);
        },
        getChartData(val) {

            if (!val) {
                return;
            }

            if (this.findChartDataKeyByKey(val.key) === 'atividade') {
                return this.atividade_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'gasto_combustivel') {
                return this.gasto_combustivel_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'temperature') {
                return this.device_temperature_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'positioning_precision') {
                return this.positioning_precision_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'vehicle_rpm') {
                return this.vehicle_rpm_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'actual_engine_percent_torque') {
                return this.actual_engine_percent_torque_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'vehicle_slip_percentage') {
                return this.vehicle_slip_percentage_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'selected_gear') {
                return this.selected_gear_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'gps_vehicle_speed') {
                return this.gps_vehicle_speed_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'engine_instantaneous_fuel_economy') {
                return this.engine_instantaneous_fuel_economy_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'number_of_satellites') {
                return this.number_of_satellites_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'altitude') {
                return this.altitude_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'gsm_signal') {
                return this.gsm_signal_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'gsm_band') {
                return this.gsm_band_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'engine_oil_level') {
                return this.engine_oil_level_series;
            } else if (this.findChartDataKeyByKey(val.key) === 'fuel_level') {
                return this.fuel_level_series;
            }
        },
        findChartDataKeyByKey(key) {
            let data = this.findChartDataByKey(key);

            if (data) {
                return data.key;
            }
        },
        getVehicleDisplayNameByID(id) {
            for (let i = 0; i < this.vehicles.length; i++) {
                if (this.vehicles[i].id === id) {
                    return this.vehicles[i].display_id;
                }
            }
        },
        //TODO ver comportamento estranho quando remove os dados
        //TODO mudar o maxLength é dinamico verificar se está removendo corretamente
        purgeSeries(list) {
            let maxLength = this.itemsCharts;
            if (list.length > maxLength) {
                list.shift();
            }
        },
        putChartDataInKeys(chartKey, keyToReplace) {
            if (this.findChartDataByKey(this.storedChosenCharts[chartKey])) {
                this[chartKey] = this.findChartDataByKey(this.storedChosenCharts[chartKey]);
                return;
            }

            this[chartKey] = this.findChartDataByKey(keyToReplace);
        },
        initializeChosenCharts() {
            this.storedChosenCharts = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS));

            if (!this.storedChosenCharts) {
                this.storedChosenCharts = {
                    chart_one_selected: 'selected_gear',
                    chart_two_selected: 'temperature',
                    chart_three_selected: 'positioning_precision',
                    chart_four_selected: 'vehicle_rpm'
                };
            }

            this.putChartDataInKeys("chart_one_selected", "selected_gear");
            this.putChartDataInKeys("chart_two_selected", "temperature");
            this.putChartDataInKeys("chart_three_selected", "positioning_precision");
            this.putChartDataInKeys("chart_four_selected", "vehicle_rpm");
        },
        handleSelectChangesChangeOne(val) {

            //se não tem nenhum selecionado no select
            if (!val || !val.key) {
                return;
            }

            this.chart_one_selected = val;
            this.chartOneData = [];
            this.chartOneData = this.getChartData(this.chart_one_selected);


            if (this.showHistoricalData) {
                return;
            }

            this.storedChosenCharts["chart_one_selected"] = val.key;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS, JSON.stringify(this.storedChosenCharts));
        },
        handleSelectChangesChangeTwo(val) {

            if (!val || !val.key) {
                return;
            }

            this.chart_two_selected = val;
            this.chartTwoData = [];
            this.chartTwoData = this.getChartData(this.chart_two_selected);

            if (this.showHistoricalData) {
                return;
            }

            this.storedChosenCharts["chart_two_selected"] = val.key;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS, JSON.stringify(this.storedChosenCharts));
        },
        handleSelectChangesChangeThree(val) {

            if (!val || !val.key) {
                return;
            }

            this.chart_three_selected = val;
            this.chartThreeData = [];
            this.chartThreeData = this.getChartData(this.chart_three_selected);

            if (this.showHistoricalData) {
                return;
            }

            this.storedChosenCharts["chart_three_selected"] = val.key;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS, JSON.stringify(this.storedChosenCharts));
        },
        handleSelectChangesChangeFour(val) {

            if (!val || !val.key) {
                return;
            }

            this.chart_four_selected = val;
            this.chartFourData = [];
            this.chartFourData = this.getChartData(this.chart_four_selected);

            if (this.showHistoricalData) {
                return;
            }

            this.storedChosenCharts["chart_four_selected"] = val.key;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_CHARTS, JSON.stringify(this.storedChosenCharts));
        },
        findChartDataByKey(key) {
            for (let i = 0; i < this.charts_types_data.length; i++) {
                if (this.charts_types_data[i].key === key) {
                    return this.charts_types_data[i];
                }
            }
        },
    },
    computed: {
        selectOne: function () {
            if (this.showHistoricalData) {
                return this.charts_types_data;
            } else {
                return this.charts_types_data.slice(4);
            }
        },
        selectThree: function () {
            if (this.showHistoricalData) {
                return this.charts_types_data.slice(1);
            } else {
                return this.charts_types_data.slice(4);
            }
        },
    },
    components: {
        AppVehiclesSelect,
        AppVehiclesSelectVehicles,
        AppVehiclesTimelineCards,
        AppVehiclesDataTable,
        AppVehiclesTrackCharts,
        AppVehiclesTrackChartsPie,

    }

}
</script>

<style scoped lang="scss">

.chart {
    height: calc(25vh - 53px);
    width: 100%;
}

.chart-pie {
    height: calc(50vh - 73px);
    width: 100%;
}

.cards-timeline {
    height: calc(100vh - 113px);
    width: 100%;
}

select:disabled {
    opacity: 0.6;

}
</style>

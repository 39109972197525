<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
                <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                    <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                    :filter_button_is_loading="isLoadingHistoricalData"
                                    :selected-vehicles-prop="selectedVehicles"
                                    :show_historical_data="showHistoricalData"
                                    :feature="'TRACK'"
                                    :show_vehicle_type="true"
                                    :show_panel="showPanel"
                                    @vehiclesChanged="vehiclesChanged"
                                    @selectedVehiclesChanged="selectedVehiclesChanged"
                                    @selectedOrganizationsChanged="selectedOrganizationsChanged"
                                    ref="filterBar">

                        <template v-slot:slot-two>
                            <app-button v-show="showHistoricalData"
                                        :disabled="isLoadingHistoricalData"
                                        type="danger"
                                        class="auto-center"
                                        style="margin-left: 5px"
                                        @handleClick="handleBackToRealTime"> Voltar
                            </app-button>

                            <select v-show="showHistoricalData"
                                    style="width: 110px"
                                    class="form-control kt-margin-5-desktop width-auto auto-center"
                                    @change="handleChangeWorkType($event)"
                                    v-model="optionsSelectWorkTypeSelected">
                                <option v-for="option in optionsSelectWorkType" v-bind:key="option.key"
                                        :value="option">
                                    {{ option.value }}
                                </option>
                            </select>

                            <app-button v-show="showHistoricalData && historicalChartsData.length > 0"
                                        :disabled="loadingReport"
                                        type="secondary"
                                        class="auto-center"
                                        style="margin-left: 15px"
                                        @handleClick="requestPDFReport">
                                Relatório
                            </app-button>
                        </template>
                    </app-filter-bar>

                </div>
                <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                    <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center"
                                @handleClick="openMaximizable"
                                :class="{ 'kt-spinner kt-spinner--v2 kt-spinner--right kt-spinner--sm kt-spinner--dark': isLoadingHistoricalData }">
                                    Filtrar
                    </app-button>
                    <app-button v-show="showHistoricalData"
                                :disabled="isLoadingHistoricalData"
                                type="danger"
                                style="margin-left: 5px;"
                                class="auto-center"
                                @handleClick="handleBackToRealTime"> Voltar
                    </app-button>
                    <app-button v-show="showHistoricalData && historicalChartsData.length > 0"
                                :disabled="loadingReport"
                                type="secondary"
                                class="auto-center"
                                style="margin-left: 7px"
                                @handleClick="requestPDFReport">
                        Relatório
                    </app-button>
                </div>
                <Dialog header="Filtrar" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                    <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                    :is_col_12="true"
                                    :filter_button_is_loading="isLoadingHistoricalData"
                                    :selected-vehicles-prop="selectedVehicles"
                                    :show_historical_data="showHistoricalData"
                                    :feature="'TRACK'"
                                    @vehiclesChanged="vehiclesChanged"
                                    @selectedVehiclesChanged="selectedVehiclesChanged"
                                    @selectedOrganizationsChanged="selectedOrganizationsChanged"
                                    ref="filterBar"/>
                </Dialog>
                <div class="kt-portlet__body no-padding">

                    <splitpanes class="default-theme" :class="{'splitpanes-height': !is_col_12}"
                                :dbl-click-splitter="false"
                                @resize="splitPanesResized"
                                @resized="splitPanesResized" :horizontal="is_col_12">
                        <pane min-size="1" :size="paneSize">
                            <div class="row no-gutters" :class="{'wraper height-maps': !is_col_12,
                                    'height-maps-open': is_col_12 && !showRightPaneCharts, 'height-maps-close': is_col_12 && showRightPaneCharts}">
                                <div class="col-12">

                                    <!--Select com o estados das maquinas-->
                                    <!--                                    <div style="position: absolute; z-index: 10; left: 20px; top: 10px"-->
                                    <div style="position: absolute; z-index: 10; left: 10px; top:10px"
                                         v-show="paneSize > 25 && !showHistoricalData">
                                        <app-vehicles-status-control
                                            @statusChanged="statusChanged">

                                        </app-vehicles-status-control>
                                    </div>

                                    <div style="position: absolute; z-index: 5; width: 94%; margin: 0 auto;"
                                         v-show="showHistoricalData && !is_col_12">
                                        <app-cards :cards="cards">
                                        </app-cards>
                                    </div>

                                    <!--Select com os tipos de mapa-->
                                    <div style="position: absolute; z-index: 10; top: 10px; right: 20px"
                                         v-show="paneSize > 20">
                                        <div class="row justify-content-center">

                                            <app-google-maps-type-control
                                                :show_work_details="showHistoricalData"
                                                ref="googleMapsTypeControl"
                                                @handleMapControlClick="handleMapControlClick">
                                            </app-google-maps-type-control>
                                        </div>
                                    </div>

                                    <div
                                        class="position-bottom"
                                        v-show="paneSize > 25">
                                        <div class="row justify-content-center">
                                            <app-google-maps-legend :there_is_work_track_data="thereIsWorkTrackData"
                                                                    :there_is_idle_track_data="thereIsIdleTrackData"
                                                                    :there_is_maneuver_track_data="thereIsManeuverTrackData"
                                                                    :there_is_travel_track_data="thereIsTravelTrackData"
                                                                    :show_at_real_time="!showHistoricalData"/>
                                        </div>
                                    </div>
                                    <div
                                        style="position: absolute; z-index: 5; bottom: 1px;  width: 99%; margin: 0 auto;">
                                        <div class="row" style="float: right">
                                            <app-google-maps-weather-legend
                                                :show_legend="weather_overlay">
                                            </app-google-maps-weather-legend>
                                        </div>
                                    </div>
                                    <app-google-maps-real-time
                                        ref="googleMapsRealTime"
                                        :vehicles_list="vehicles"
                                        :selected_organization_id="selectedOrganizationId"
                                        :selected_vehicles="selectedVehicles"
                                        :realtime_data="realtimeDataMarkers"
                                        :map_type="mapsTypesSelected"
                                        :show_panel="showPanel"
                                        :selected_statuses="vehiclesStatusesSelected"
                                        :positionCenter="center"
                                        :tilesData="tilesData"
                                        :weather_overlay="weather_overlay"
                                        :organization_identification="organizationSelected"
                                        :marker_historical="marker_historical"
                                        :marker_location="markerLocation"
                                        :application_tiles_data="applicationTilesData"
                                        :application_on="applicationOn"
                                        :speed_tiles_data="speedTilesData"
                                        :speed_on="speedOn"
                                        :humidity_tiles_data="humidityTilesData"
                                        :humidity_on="humidityOn"
                                        :track_on="trackOn"
                                        :operational_alerts="operationalAlerts"
                                        :report_dates="reportDates"
                                        :display_id_vehicles="displayIdVehicles"
                                        :marker_init_data="markerInitData"
                                        :marker_end_data="markerEndData"
                                        :gaps="gaps"
                                        :noTrackOn="noTrackOn"
                                        style="position: relative; width: 100%; height: 100%"
                                        class="google-maps"
                                        @handleClickMarker="handleClickMarker"
                                        @handleClickCloseInfoWindow="handleClickCloseInfoWindow"
                                        @handleZoomChanged="handleZoomChanged"
                                        @handleBoundsChanged="handleBoundsChanged"
                                        @updateVehiclesIdsInVisibleCluster="updateVehiclesIdsInVisibleCluster"/>
                                    <div style="position: absolute; z-index: 5; bottom: 1px;  width: 99%; margin: 0 auto;">
                                        <div class="row" style="float: right; width: 150px;position:absolute; bottom:5px; right:5px;">
                                            <img src="../../../assets/images/brands/poweredByIotag.png" class="img-fluid">
                                        </div>
                                        <div class="line" :class="{'active': locationOn}" :style="{bottom: bottomButton}" style="float: right;position:relative;">
                                            <div :class="{'item-active': locationOn, 'item': !locationOn}" @click="handleMapControlClick('location')">
                                                <img src="@/assets/images/maps-type-control/location.png" alt="">
                                            </div>
                                        </div>
                                        <div class="line" :class="{'active-panel': showPanel}" :style="{bottom: bottomButtonPanel}" style="float: right;position:relative; left: 35px;">
                                            <div v-if="showPanelStatus" @click="handleShowDetails()">
                                                <i style="color: white;" class="pi pi-eye icon-eye"></i>
                                            </div>
                                            <div v-else @click="handleShowDetails()">
                                                <i style="color: black;" class="pi pi-eye-slash icon-eye"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </pane>

                        <pane min-size="1" v-if="showRightPaneCharts" :size="100 - paneSize">
                            <app-charts-right-side
                                ref="chartsRightSide"
                                :events-report-clustered="eventsReportClustered"
                                :eventsReport="eventsReport"
                                :selectedVehicles="selectedVehicles"
                                :realtimeChartsData="realtimeChartsData"
                                :vehicles="vehicles"
                                :historicalChartsData="historicalChartsData"
                                :historicalPieChartsData="historicalPieChartsData"
                                :showHistoricalData="showHistoricalData"
                                :itemsCharts="100 - paneSize"
                                @customMouseOver="customMouseOver"
                                @mouseOverEvent="mouseOverEvent">
                            </app-charts-right-side>
                        </pane>
                    </splitpanes>
                </div>
            </div>

        <app-vehicles-new-selection-dialog :display_dialog="showNewVehiclesSelectionDialog"
                                           @handleClose="handleCloseNewSelectionDialog"/>
        <app-real-time-report ref="report"
                              :selected_vehicles="selectedVehicles"
                              :events="eventsReport"
                              :events_clustered="eventsReportClustered"
                              :cards_data="cardsData"
                              :historical_pie_charts_data="historicalPieChartsData"
                              :report_dates="reportDates"
                              @reportHasBeenDownloaded="reportHasBeenDownloaded"/>
        </div>
</template>

<script>

    import {Splitpanes, Pane} from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'

    import AppFilterBar from '../../common/AppFilterBar';
    import AppVehiclesSelectVehicles from "./AppVehiclesSelectVehicles";
    import AppDateTimeControlBar from "../../common/AppDateTimeControlBar";

    import AppGoogleMapsLegend from "../../common/AppGoogleMapsLegend";
    import AppGoogleMapsRealTime from "./AppGoogleMapsRealTime";
    import AppGoogleMapsTypeControl from "@/components/common/AppGoogleMapsTypeControl";
    import AppGoogleMapsWeatherLegend from "./AppGoogleMapsWeatherLegend";
    import AppVehiclesStatusControl from "./AppVehiclesStatusControl";
    import AppVehiclesNewSelectionDialog from "./AppVehiclesNewSelectionDialog";


    import AppChartsRightSide from "./AppChartsRightSide";
    import AppCards from "./AppCards";

    import Button from 'primevue/button';
    import AppButton from "../../common/AppButton";
    import MultiSelect from 'primevue/multiselect';
    import Dropdown from 'primevue/dropdown';
    import Dialog from 'primevue/dialog';

    import {MAPS_OVERLAY_WORK_TYPES, VEHICLES_STATUSES_LIST} from './AppOptions';
    import {OPTIONS_SELECT_WORK_TYPE} from './AppOptions';

    import RealtimeService from '../../../services/RealtimeService';
    import TrackService from "../../../services/TrackService";
    import EventsService from "../../../services/EventsService";
    import VehiclesService from "../../../services/VehiclesService";
    import FieldsService from "../../../services/FieldsService";
    import OperationalAlertsOccurrencesService from "../../../services/OperationalAlertsOccurrencesService";
    import AlertsService from "../../../services/AlertsService";
    import ReportsService from "../../../services/ReportsService";
    import hasFeatureEnabled from "@/mixins/OrganizationFeatureFlagsMixin";


    import {MAPS_OVERLAY_OPEN_WEATHER_TYPES} from './AppOptions';
    import {MAPS_OVERLAY_WEATHER_TYPES} from './AppOptions';
    import {MAPS_DEFAULT_CONFIGS} from './AppOptions';

    import moment from 'moment';

    import AppRealTimeReport from "@/components/views/real-time/report/AppRealTimeReport";
    import html2canvas from "html2canvas";
    import {router} from "@/router";

    export default {
        mixins: [hasFeatureEnabled],
        created() {
            window.addEventListener('keydown', this.keyF11);
        },
        beforeMount() {
            if (!this.hasFeatureEnabled("TRACK")) {
                router.push({name: 'profile'});
                return;
            }
            this.retrievePaneSize();
        },
        mounted() {
            this.mapsTypesSelected = this.mapsTypes[1].value;
            this.realtimeService = new RealtimeService();
            this.trackService = new TrackService();
            this.optionsSelectWorkTypeSelected = this.optionsSelectWorkType[0];
            this.eventsService = new EventsService();
            this.vehiclesService = new VehiclesService();
            this.fieldsService = new FieldsService();
            this.operationalOccurrencesAlertsService = new OperationalAlertsOccurrencesService();
            this.alertsService = new AlertsService();
            this.reportsService = new ReportsService();
            this.showDialogFilters = this.isScreenMobile;
            this.is_col_12 = this.isScreenMobile;
            this.bottomButton = this.isScreenMobile ? '105px' : '50px';
            this.bottomButtonPanel = this.isScreenMobile ? '155px' : '105px';
        },

        data() {
            return {
                reportsService: null,
                showDialogFilters: false,
                is_col_12: false,
                displayMaximizable: false,
                bottomButtonPanel: null,
                bottomButton: null,
                markerLocation: null,
                trackService: null,
                realtimeService: null,
                eventsService: null,
                vehiclesService: null,
                fieldsService: null,
                operationalOccurrencesAlertsService: null,
                alertsService: null,
                showPanel: false,
                showPanelStatus: false,

                locationOn: false,
                showFields: false,

                isLoadingTiles: false,
                isLoadingSensorData: false,
                isLoadingEvents: false,
                isLoadingApplicationTiles: false,
                isLoadingVelocityTiles: false,
                isLoadingHumidityTiles: false,

                paneSize: 72,
                KEY_LOCAL_STORAGE_REAL_TIME_PANE_SIZE: 'REAL_TIME_PANE_SIZE',

                realtimeChartsData: [],
                lastRealTimeData: [],

                eventsReportClustered: [],
                eventsReport: [],
                center: {lat: -25.407688, lng: -49.253990},
                vehicles: [], // lista com todas as maquinas
                vehiclesWithData: [], // lista de maquinas que tem dados
                vehiclesIdsInVisibleCluster: [],

                showHistoricalData: false,
                showRightPaneCharts: false,

                thereIsWorkTrackData: true,
                thereIsIdleTrackData: true,
                thereIsManeuverTrackData: true,
                thereIsTravelTrackData: true,

                selectedOrganizations: [],
                cards: [],
                cardsData: {},
                vehiclesStatusesSelected: [],
                vehiclesStatusesList: VEHICLES_STATUSES_LIST,

                //https://developers.google.com/maps/documentation/javascript/maptypes
                mapsTypes: [{name: 'Mapa', value: 'roadmap'}, {name: 'Satélite', value: 'hybrid'}],
                mapsTypesSelected: null,

                selectedVehicles: [],
                realtimeDataMarkers: [],
                TIMEOUT_REFRESH_DATA: 2000, // Tempo para pedir os dados da maquina,
                refreshIntervalIdTrack: null,
                refreshIntervalIdTrackLme: null,
                tilesData: [],
                applicationTilesData: [],
                speedTilesData: [],
                humidityTilesData: [],
                showBtnBackToRealtime: false,

                optionsSelectWorkType: OPTIONS_SELECT_WORK_TYPE,
                optionsSelectWorkTypeSelected: null,
                historicalChartsData: [],
                historicalPieChartsData: {
                    atividade_series: [],
                    gasto_combustivel_series: [],
                },

                MAX_REQUEST_VEHICLES_WITHOUT_DEVICES: 5,
                requestCount: 0,
                FIELDS_PAGE_SIZE: 750,
                weather_overlay: null,
                marker_historical: null,
                mapBoundsAndZoom: {
                    zoom: MAPS_DEFAULT_CONFIGS.ZOOM
                },
                fieldsOverlayActive: 'TILES',
                fieldsOverlayActiveOptions: {
                    TILES: 'TILES',
                    DECK: 'DECK'
                },
                showNewVehiclesSelectionDialog: false,
                vehicleIdToStartSelection: "",
                loadingReport: false,
                reportDates: {},
                organizationChangedAfterRequest: false,
                vehiclesChangedAfterRequest: false,
                applicationOn: false,
                speedOn: false,
                humidityOn: false,
                trackOn: false,
                operationalAlertsOn: false,
                machineAlertsOn: false,
                operationalAlerts: [],
                MAX_ALERTS_PAGES: 10,
                pageableRequestStart: 0,
                pageableRequestStartLme: 0,
                currentRequestId: null,
                currentRequestIdLme: null,
                locationBlocked: false,
                displayIdVehicles: [],
                markerInitData: null,
                markerEndData: null,
                gaps: [],
                noTrackOn: false
            }
        },
        methods: {
            openMaximizable() {
                this.displayMaximizable = true;
            },
            handleShowDetails(){
                // Verifica se existem filtros selecionados
                if (this.vehicles.length === 0 && !this.showPanelStatus) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Selecione o cliente',
                        detail: 'Selecione um cliente para continuar',
                        life: 5000
                    });
                    return;
                }

                // Visualização Full Screen
                let doc = window.document;
                let docEl = doc.documentElement;

                let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
                let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
                let isScreenFull = doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement;

                // Se o painel estiver ligado, ele é desligado
                if(this.showPanelStatus){
                    this.getAllVehiclesMarkers();
                    this.showPanelStatus = !this.showPanelStatus;
                    this.selectedVehicles = [];
                    this.showPanel = false;
                    if (isScreenFull) {
                        cancelFullScreen.call(doc);
                    }
                    return;
                }

                requestFullScreen.call(docEl);

                // Balão com nome da frota em cima dos markers
                this.showPanel = true;
                this.selectedVehicles = this.selectedVehicles.length > 0 ? this.selectedVehicles : this.vehicles.slice();

                // Se algo foi filtrado executa o método para limpar o resultado
                if(this.showHistoricalData){
                    this.handleBackToRealTime();
                }
                this.showRightPaneCharts = false;

                this.showPanelStatus = !this.showPanelStatus;
            },
            keyF11(event) {
                if (event.key === 'F11') {
                    event.preventDefault();
                }
            },
            reportHasBeenDownloaded() {
                this.loadingReport = false;
            },
            async generateCanvas(canvas) {
                let div = document.getElementById('google-maps-report');
                div.innerHTML = "";
                let image = new Image();
                image.id = "pic";
                image.src = canvas.toDataURL();
                image.style.width = '100%';
                image.style.height = 'auto';
                div.appendChild(image);
                this.$refs.report.generateReport();
            },
            requestPDFReport() {
                this.loadingReport = true;

                this.$toast.add({
                    severity: 'success',
                    summary: 'Relatório solicitado com sucesso',
                    detail: 'Aguarde enquanto o relatório carrega. Isso pode levar algum tempo.',
                    life: 5000
                });

                html2canvas(document.querySelector(".google-maps"), {
                    useCORS: true, allowTaint: false
                }).then(canvas => {
                    this.generateCanvas(canvas)
                });
            },
            statusChanged(val) {
                this.requestCount = 0;
                this.vehiclesStatusesSelected = val;
                this.getAllVehiclesMarkers();
            },
            customMouseOver(values) {
                if (this.showHistoricalData) {
                    for (let i = 0; i < this.historicalChartsData.length; i++) {
                        let index = this.binarySearch(this.historicalChartsData[i].data, values.x);

                        if (index >= 0 && this.isLatLngValid(this.historicalChartsData[i].data[index])) {
                            this.marker_historical = {
                                lat: this.historicalChartsData[i].data[index].lat,
                                lng: this.historicalChartsData[i].data[index].lng
                            };
                            break;
                        }
                    }
                }
            },
            mouseOverEvent(values) {
                if (!this.showHistoricalData) {
                    this.markerInitData = null;
                    this.markerEndData = null;
                    return;
                }

                if (!values) {
                    this.markerInitData = null;
                    this.markerEndData = null;
                    return;
                }

                for (let i = 0; i < this.historicalChartsData.length; i++) {
                    if (values.vid !== this.historicalChartsData.id) {
                        return;
                    }

                    let tsArray = this.historicalChartsData[i].data.map(obj => obj.ts);
                    this.markerInitData = this.createMarkerTimeline(this.historicalChartsData[i].data[this.findClosestValueIndex(tsArray, (values.event_start * 1000))])
                    this.markerEndData = this.createMarkerTimeline(this.historicalChartsData[i].data[this.findClosestValueIndex(tsArray, (values.event_end * 1000))])

                }
            },
            createMarkerTimeline(markerData) {
                if (!markerData) {
                    return null;
                }
                return {
                    lat: markerData.lat,
                    lng: markerData.lng
                };
            },
            findClosestValueIndex(array, goal) {
                return array.reduce((closestIndex, currentValue, currentIndex, arr) => {
                    return Math.abs(currentValue - goal) < Math.abs(arr[closestIndex] - goal) ? currentIndex : closestIndex;
                }, 0);
            },
            isLatLngValid(data) {
                if (!data || !data.lat || !data.lng) {
                    return false;
                }
                return true;
            },

            binarySearch(array, targetValue) {
                var min = 0;
                var max = array.length - 1;
                var guess;

                while (min <= max) {
                    guess = Math.floor((max + min) / 2);

                    if (array[guess].ts === targetValue) {
                        return guess;
                    } else if (array[guess].ts < targetValue) {
                        min = guess + 1;
                    } else {
                        max = guess - 1;
                    }
                }
                return -1;
            },
            binarySearchClosest(array, targetValue) {
                var min = 0;
                var max = array.length - 1;
                var guess;

                while (min <= max) {
                    guess = Math.floor((max + min) / 2);

                    if (array[guess].ts === targetValue) {
                        return guess;
                    } else if (array[guess].ts < targetValue) {
                        min = guess + 1;
                    } else {
                        max = guess - 1;
                    }
                }
                return -1;
            },
            handleBackToRealTime() {

                this.marker_historical = null;

                try {
                    this.$refs.chartsRightSide.restoreStateChartsRealTime();
                } catch (e) {
                    //
                }
                this.requestCount = 0;
                this.clearHistoricalData();
                this.getAllVehiclesMarkers();
                this.realtimeChartsData = [];
            },

            splitPanesResized(event) {
                //se for = 100 está mostrando so um
                if (event[0].size !== 100) {
                    this.storePaneSize(event[0].size);
                    this.paneSize = event[0].size;
                }
            },
            storePaneSize(size) {
                localStorage.setItem(this.KEY_LOCAL_STORAGE_REAL_TIME_PANE_SIZE, JSON.stringify(size));
            },
            retrievePaneSize() {
                let size = JSON.parse(localStorage.getItem(this.KEY_LOCAL_STORAGE_REAL_TIME_PANE_SIZE));
                if (size) {
                    this.paneSize = size;
                }
            },
            async handleCloseNewSelectionDialog(clearSelection) {
                this.showNewVehiclesSelectionDialog = false;
                if (clearSelection) {
                    await this.$refs.filterBar.clearFilters();
                    this.handleClickMarker(this.vehicleIdToStartSelection);
                }
            },
            handleClickMarker(id) {
                if (!this.selectedVehicles.find(v => v.id === id)) {
                    let temp = this.vehicles.find(v => v.id === id);

                    if (!temp) {
                        return;
                    }

                    if(!this.showPanel){
                        //Click no marker deve selecionar so um por vez
                        this.selectedVehicles = [];
                        this.selectedVehicles.push(temp);
                    } else{
                        this.selectedVehicles.push(temp);
                    }
                }
            },
            handleClickCloseInfoWindow(vehicleId) {
                //fechou a infowindow tem que desmarcar a maquina
                let index = this.selectedVehicles.findIndex(v => v.id === vehicleId);

                if (index >= 0) {
                    this.selectedVehicles.splice(index, 1);
                }
            },
            selectedOrganizationsChanged(val) {
                this.selectedOrganizations = val;
                //TODO tem que desativar os tiles ou o deck
                this.removeFieldsFromRealTime();
                this.resetFieldsButton();
                this.organizationChangedAfterRequest = true;
            },
            selectedVehiclesChanged(val) {
                this.selectedVehicles = val;
            },
            vehiclesChanged(val) {
                this.vehicles = val;
                this.getAllVehiclesMarkers();
            },
            updateVehiclesIdsInVisibleCluster(val) {
                this.vehiclesIdsInVisibleCluster = val;
            },
            getAllVehiclesMarkers() {
                this.pageableRequestStart = 0;
                this.pageableRequestStartLme = 0;
                this.realtimeDataMarkers = [];
                this.currentRequestId = this.guidGenerator();
                this.currentRequestIdLme = this.guidGenerator();
                this.$refs.googleMapsRealTime.removeAllMarkers();
                this.cancelRequestsRealTime()
                this.requestPageableRealTimeData(this.currentRequestId);
                this.requestPageableRealTimeDataLme(this.currentRequestIdLme);
            },
            guidGenerator() {
                let S4 = function () {
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                };
                return (S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
            },
            requestPageableRealTimeDataLme(requestId) {
                let request = [];
                const PAGEABLE_REQUEST_MAX = 100;

                if (this.currentRequestIdLme !== requestId) {
                    return
                }

                let vehiclesToRequestOldRealTime = this.vehicles.filter((vehicle) => {return vehicle.real_time_by_lme})

                let vehiclesSliced = vehiclesToRequestOldRealTime.slice(this.pageableRequestStartLme,
                    Math.min(this.pageableRequestStartLme + PAGEABLE_REQUEST_MAX, vehiclesToRequestOldRealTime.length));

                vehiclesSliced
                    .forEach(vehicle => {
                        if (this.containsInListById(vehicle.id, this.selectedVehicles) >= 0) {
                            return;
                        }

                        let info = {
                            vin: vehicle.identification_number,
                            mac_address: vehicle.mac_address,
                            vid: vehicle.id,
                            org_id: vehicle.organization_id,
                            lme_version: 1,
                            expiration_time: 10000,
                            frequency: 2000
                        };
                        request.push(info);
                    })


                this.realtimeService.requestDataLme(request)
                    .then((response) => {
                        if (this.currentRequestIdLme !== requestId) {
                            return;
                        }
                        if (response) {
                            this.updateRealtimeMarkers(response, vehiclesSliced);
                        }
                    }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    if (this.currentRequestIdLme !== requestId) {
                        return;
                    }

                    if (this.pageableRequestStartLme + PAGEABLE_REQUEST_MAX < vehiclesToRequestOldRealTime.length) {
                        this.pageableRequestStartLme = this.pageableRequestStartLme + PAGEABLE_REQUEST_MAX;
                        this.requestPageableRealTimeDataLme(requestId)
                        return;
                    }

                    this.pageableRequestStartLme = 0;
                    this.scheduleGetVehiclesTrackLme();
                });
            },
            requestPageableRealTimeData(requestId) {
                let request = [];
                const PAGEABLE_REQUEST_MAX = 100;

                if (this.currentRequestId !== requestId) {
                    return
                }

                let vehiclesToRequestOldRealTime = this.vehicles.filter((vehicle) => {return !vehicle.real_time_by_lme})

                let vehiclesSliced = vehiclesToRequestOldRealTime.slice(this.pageableRequestStart,
                    Math.min(this.pageableRequestStart + PAGEABLE_REQUEST_MAX, vehiclesToRequestOldRealTime.length));

                vehiclesSliced
                    .forEach(vehicle => {
                        let info = {
                            chassis: vehicle.identification_number,
                            device: vehicle.mac_address,
                            id: vehicle.id
                        };
                        request.push(info);
                    })


                this.realtimeService.requestData(request)
                    .then((response) => {
                        if (this.currentRequestId !== requestId) {
                            return;
                        }
                        if (response) {
                            this.updateRealtimeMarkers(response, vehiclesSliced);
                        }
                    }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    if (this.currentRequestId !== requestId) {
                        return;
                    }

                    if (this.pageableRequestStart + PAGEABLE_REQUEST_MAX < vehiclesToRequestOldRealTime.length) {
                        this.pageableRequestStart = this.pageableRequestStart + PAGEABLE_REQUEST_MAX;
                        this.requestPageableRealTimeData(requestId)
                        return;
                    }

                    this.pageableRequestStart = 0;
                    this.scheduleGetVehiclesTrack();
                });
            },
            updateRealtimeMarkers(response, vehiclesSliced) {
                let tempRealtimeDataMarkers = [];
                let idsMap = this.realtimeDataMarkers.map(obj => obj.id);

                vehiclesSliced.forEach(vehicle => {
                    if (!response.map((obj => obj.id)).includes(vehicle.id)) {
                        if (!idsMap.includes(vehicle.id)) {
                            tempRealtimeDataMarkers.push({
                                'id': vehicle.id,
                                '_035': vehicle.identification_number,
                                "_009": vehicle.latitude,
                                "_010": vehicle.longitude,
                                "_005": vehicle.last_contact,
                                "is_unavailable": vehicle.unavailability_date
                            })
                            return;
                        }
                    }
                })

                response.forEach(eachResponse => {
                    if (!idsMap.includes(eachResponse.id)) {
                        tempRealtimeDataMarkers.push(eachResponse)
                        return;
                    }

                    let index = this.realtimeDataMarkers.findIndex((obj) => obj.id === response.id);

                    if (index < 0) {
                        return;
                    }

                    this.realtimeDataMarkers[index] = eachResponse;
                });

                if (tempRealtimeDataMarkers.length > 0) {
                    this.realtimeDataMarkers.push(...tempRealtimeDataMarkers);
                }
            },
            getRealTimeData() {

                //se saiu da tela mas vai fazer algum request, realiza o cancelamento
                if (!this.$route.name.includes('vehicles-track')) {
                    this.cancelRequestsRealTime();
                    return;
                }

                if (this.showHistoricalData) {
                    return;
                }

                let vm = this;

                if (!this.vehicles || this.vehicles.length === 0) {
                    return;
                }

                let request = [];
                this.vehicles.forEach(vehicle => {
                    if (!this.needsToRequestRealTimeData(vehicle)) {
                        return;
                    }

                    if (vehicle.real_time_by_lme) {
                        return;
                    }

                    if (this.containsInListById(vehicle.id, this.selectedVehicles) < 0 &&
                        !this.vehiclesIdsInVisibleCluster.includes(vehicle.id)) {
                        return;
                    }

                    let info = {
                        chassis: vehicle.identification_number,
                        device: vehicle.mac_address,
                        id: vehicle.id
                    };

                    //se for uma maquina selecionada tem que pedir mais dados
                    if (this.containsInListById(vehicle.id, this.selectedVehicles) >= 0) {
                        info.data = [6, 7, 8, 11, 12, 58, 59, 60, 61, 76, 111, 118, 769, 772, 774, 777, 828, 833];
                    }
                    request.push(info);
                })

                this.organizationChangedAfterRequest = false;
                this.vehiclesChangedAfterRequest = false;
                this.realtimeService.requestData(request)
                    .then((response) => {
                        vm.prepareRealTimeData(response);
                    }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    if (this.requestCount <= this.MAX_REQUEST_VEHICLES_WITHOUT_DEVICES) {
                        this.requestCount++;
                    }
                });
            },
            getRealTimeDataLme() {

                //se saiu da tela mas vai fazer algum request, realiza o cancelamento
                if (!this.$route.name.includes('vehicles-track')) {
                    this.cancelRequestsRealTime();
                    return;
                }

                if (this.showHistoricalData) {
                    return;
                }

                let vm = this;

                if (!this.vehicles || this.vehicles.length === 0) {
                    return;
                }

                let request = [];
                this.vehicles.forEach(vehicle => {
                    if (!this.needsToRequestRealTimeData(vehicle)) {
                        return;
                    }

                    if (!vehicle.real_time_by_lme) {
                        return;
                    }

                    if (this.containsInListById(vehicle.id, this.selectedVehicles) < 0 &&
                        !this.vehiclesIdsInVisibleCluster.includes(vehicle.id)) {
                        return;
                    }

                    let info = {
                        vin: vehicle.identification_number,
                        mac_address: vehicle.mac_address,
                        vid: vehicle.id,
                        org_id: vehicle.organization_id,
                        lme_version: 1,
                        expiration_time: 10000,
                        frequency: 2000
                    };

                    //se for uma maquina selecionada tem que pedir mais dados
                    if (this.containsInListById(vehicle.id, this.selectedVehicles) >= 0) {
                        info.lme_version = 2;

                        if (vehicle.lme_version) {
                            info.lme_version = vehicle.lme_version;
                        }
                    }
                    request.push(info);
                })

                this.organizationChangedAfterRequest = false;
                this.vehiclesChangedAfterRequest = false;
                this.realtimeService.requestDataLme(request)
                    .then((response) => {
                        vm.prepareRealTimeData(response);
                    }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    if (this.requestCount <= this.MAX_REQUEST_VEHICLES_WITHOUT_DEVICES) {
                        this.requestCount++;
                    }
                });
            },
            needsToRequestRealTimeData(vehicleData) {
                return vehicleData.device_id;
            },
            prepareRealTimeData(data) {
                //Também verifica após resposta do servidor
                if (this.showHistoricalData) {
                    return;
                }

                if (this.organizationChangedAfterRequest || this.vehiclesChangedAfterRequest) {
                    this.getRealTimeData();
                    return;
                }

                data.forEach((eachData) => {
                    let vehicle = this.vehicles.find((vehicle) => {return vehicle.id === eachData.id})
                    if (!vehicle) {
                        return
                    }
                    eachData.is_unavailable = vehicle.unavailability_date
                })

                if (data && data.length > 0) {
                    this.realtimeDataMarkers = data;
                    this.realtimeChartsData = data;
                }
            },
            containsInListById(id, list) {
                if (!list || list.length === 0) {
                    return -1;
                }
                return list.findIndex(item => item.id === id);
            },
            scheduleGetVehiclesTrack() {
                let vm = this;
                //so deixa agendar se n foi agendado anteriormente
                if (!this.refreshIntervalIdTrack) {
                    this.refreshIntervalIdTrack = setInterval(function () {
                        vm.getRealTimeData();
                    }, vm.TIMEOUT_REFRESH_DATA)
                }
            },
            scheduleGetVehiclesTrackLme() {
                let vm = this;
                //so deixa agendar se n foi agendado anteriormente
                if (!this.refreshIntervalIdTrackLme) {
                    this.refreshIntervalIdTrackLme = setInterval(function () {
                        vm.getRealTimeDataLme();
                    }, vm.TIMEOUT_REFRESH_DATA)
                }
            },
            cancelRequestsRealTime() {
                clearInterval(this.refreshIntervalIdTrack);
                this.refreshIntervalIdTrack = null;

                clearInterval(this.refreshIntervalIdTrackLme);
                this.refreshIntervalIdTrackLme = null;

                this.realtimeChartsData = [];
            },
            validateDataPreRequest(dates, selectedOrganizations, selectedVehicles) {

                if (selectedVehicles.length === 0) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Selecione a organização',
                        detail: 'Selecione uma organização para continuar',
                        life: 5000
                    });
                    return;
                }

                if (this.showPanel) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Desligue o monitoramento',
                        detail: 'Desligue o modo de monitoramento para continuar',
                        life: 5000
                    });
                    return;
                }

                this.displayMaximizable = false;
                this.reportDates = dates;
                this.cards = [];
                this.getTilesList(dates, selectedVehicles);
            },
            handleMapControlClick(val) {
                switch (val) {
                    case 'roadmap':
                        this.mapsTypesSelected = 'roadmap';
                        break;
                    case 'hybrid':
                        this.mapsTypesSelected = 'hybrid';
                        break;
                    case 'fields':
                        this.showHideFields();
                        break;
                    case 'noTrack':
                        this.noTrackOn = !this.noTrackOn;
                        break;
                    case 'operationalAlerts':
                        this.operationalAlertsOn = !this.operationalAlertsOn;
                        this.selectedVehicles.forEach(vehicle => {
                            this.showHideOperationalAlerts(0, vehicle.id);
                        })
                        break;
                    case 'machineAlerts':
                        this.machineAlertsOn = !this.machineAlertsOn;
                        this.selectedVehicles.forEach(vehicle => {
                            this.showHideMachineAlerts(0, vehicle.id);
                        })
                        break;
                    case 'location':
                        this.locationOn = !this.locationOn;
                        this.getCurrentLocation();
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                        this.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION);
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                        this.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE);
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                        this.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.CLOUDS);
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                        this.setupWeatherOverlay(MAPS_OVERLAY_WEATHER_TYPES.REMOVE);
                        break;
                    case MAPS_OVERLAY_WORK_TYPES.TRACK:
                        this.trackOn = !this.trackOn;
                        break;
                    case MAPS_OVERLAY_WORK_TYPES.APPLICATION:
                        this.applicationOn = !this.applicationOn;
                        this.speedOn = false;
                        this.humidityOn = false;
                        break;
                    case MAPS_OVERLAY_WORK_TYPES.SPEED:
                        this.speedOn = !this.speedOn;
                        this.humidityOn = false;
                        this.applicationOn = false;
                        break;
                    case MAPS_OVERLAY_WORK_TYPES.HUMIDITY:
                        this.humidityOn = !this.humidityOn;
                        this.speedOn = false;
                        this.applicationOn = false;
                        break;
                    default:
                    // console.log("Valor inesperado: ", val)
                }
            },

            setupWeatherOverlay(overlay) {
                switch (overlay) {
                    case MAPS_OVERLAY_WEATHER_TYPES.PRECIPITATION:
                        this.weather_overlay = MAPS_OVERLAY_OPEN_WEATHER_TYPES.PRECIPITATION;
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.TEMPERATURE:
                        this.weather_overlay = MAPS_OVERLAY_OPEN_WEATHER_TYPES.TEMPERATURE;
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.CLOUDS:
                        this.weather_overlay = MAPS_OVERLAY_OPEN_WEATHER_TYPES.CLOUDS;
                        break;
                    case MAPS_OVERLAY_WEATHER_TYPES.REMOVE:
                        this.weather_overlay = MAPS_OVERLAY_WEATHER_TYPES.REMOVE;
                        break;
                    default:
                        console.log("Valor inesperado: ", overlay)
                }

                if (this.$refs.googleMapsRealTime) {
                    this.$refs.googleMapsRealTime.setupWeatherOverlay();
                }
            },

            isThereJustOneOrganizationToOpenFieldsManager() {
                if (this.selectedOrganizations.length === 0) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Escolha uma organização',
                        detail: 'Escolha uma organização para acessar locais',
                        life: 3000
                    });
                    return false;
                }

                if (this.selectedOrganizations.length > 1) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Escolha uma organização',
                        detail: 'Escolha apenas uma organização para acessar locais',
                        life: 3000
                    });
                    return false;
                }

                return true;
            },
            showHideFields() {


                if (this.showFields) {
                    this.removeFieldsFromRealTime();
                    return;
                }


                if (!this.isThereJustOneOrganizationToOpenFieldsManager()) {
                    this.resetFieldsButton();
                    return;
                }

                this.customerHasFields(this.selectedOrganizations[0].id)

            },

            getFields() {
                this.fieldsService.isAuthorized(this.selectedOrganizations[0].id).then(() => {
                    if (this.useFieldsTiles) {
                        this.getFieldsTiles();
                    } else {
                        this.getFieldsDeck();
                    }

                    this.showFields = true;
                }).catch((error) => {
                    this.resetFieldsButton();
                    if (!error.response || error.response.status !== 403) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação. Tente novamente',
                            life: 5000
                        });
                        return;
                    }
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Você não tem acesso a esta funcionalidade',
                        life: 5000
                    });
                })
            },
            getFieldsTiles() {
                this.$refs.googleMapsRealTime.setupTilesFieldsOverlay(this.selectedOrganizations[0].id);
            },
            customerHasFields(orgId) {
                this.fieldsService.getNumberOfFieldsV3(orgId)
                    .then((response) => {
                        if (response.number_of_fields > 0) {
                            this.getFields();
                        } else {
                            this.$toast.add({
                                severity: 'info',
                                summary: 'Não foram localizados locais',
                                detail: 'Nenhum local localizado para a organização',
                                life: 5000
                            });
                        }
                    }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Não foi possível realizar a operação',
                        detail: 'Não foi possivel mostrar os locais da organização',
                        life: 5000
                    });
                })
            },
            getFieldsDeck() {

                let params = {
                    swLat: this.mapBoundsAndZoom.sw_lat,
                    swLng: this.mapBoundsAndZoom.sw_lng,
                    neLat: this.mapBoundsAndZoom.ne_lat,
                    neLng: this.mapBoundsAndZoom.ne_lng,
                };

                this.fieldsService.getFieldsV3(this.selectedOrganizationId, params)
                    .then((response) => {
                        this.fieldsToMap(response);
                    }).catch((error) => {
                    console.log(error)
                })
            },
            resetFieldsButton() {
                this.$refs.googleMapsTypeControl.resetFieldsButton();
            },
            fieldsToMap(response) {
                this.$refs.googleMapsRealTime.fieldsToMap(response.fields);
            },
            removeFieldsFromRealTime() {
                this.showFields = false;
                this.$refs.googleMapsRealTime.removeFieldsFromRealTime();
                this.$refs.googleMapsRealTime.removeFieldsTiles();
            },
            showHideOperationalAlerts(page, vehicleId) {

                if (!this.operationalAlertsOn) {
                    this.$refs.googleMapsRealTime.removeOperationalAlertsFromRealTime();
                    return;
                }

                let params = {
                    page: page,
                    start: moment.tz(this.reportDates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end: moment.tz(this.reportDates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                };

                this.operationalOccurrencesAlertsService.findOccurrencesByVehicle(vehicleId, params).then(response => {
                    this.$refs.googleMapsRealTime.operationalAlertsToMap(response.occurrences);

                    if (response.current_page >= response.total_pages - 1 || response.current_page >= this.MAX_ALERTS_PAGES) {
                        return;
                    }

                    this.showHideOperationalAlerts(response.current_page + 1, vehicleId);
                }).catch(error => {
                    console.log(error)
                })
            },
            showHideMachineAlerts(page, vehicleId) {
                if (!this.machineAlertsOn) {
                    this.$refs.googleMapsRealTime.removeMachineAlertsFromRealTime();
                    return;
                }

                let params = {
                    start: moment.tz(this.reportDates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end: moment.tz(this.reportDates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                };

                this.alertsService.findAlertsByVehicleAndPage(vehicleId, page, params).then(response => {
                    this.$refs.googleMapsRealTime.machineAlertsToMap(response.alerts_body_list);

                    if (response.current_page >= response.total_pages - 1 || response.current_page >= this.MAX_ALERTS_PAGES) {
                        return;
                    }

                    this.showHideMachineAlerts(response.current_page + 1, vehicleId);
                }).catch(error => {
                    console.log(error)
                })
            },
            getCurrentLocation() {
                let options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                };

                let firstCall = true; // Verifica se é a primeira chamada
                let errorMessage;

                if (!this.locationOn) {
                    clearInterval(this.getLocationInterval);
                    this.markerLocation = null;
                    return;
                }

                const getLocation = () => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((pos) => {
                            let latLng = { lat: pos.coords.latitude, lng: pos.coords.longitude };
                            if(firstCall){
                                this.center = latLng; // Executa somente na primeira chamada se o cliente solicitou a localização atual
                                firstCall = false;
                            }
                            this.markerLocation = latLng;
                        }, (error) => {
                            // Traduz a mensagem caso o erro seja: User denied Geolocation
                            if(error.message == "User denied Geolocation"){
                                errorMessage = "Localização negada pelo usuário"
                            } else{
                                errorMessage = error.message
                            }
                            this.$toast.add({
                                severity: 'error', summary: 'Falha',
                                detail: errorMessage, life: 3000
                            });
                            clearInterval(this.getLocationInterval);
                            this.markerLocation = null;
                            this.locationOn = false;
                        }, options);
                    } else {
                        this.$toast.add({
                            severity: 'error', summary: 'Falha',
                            detail: 'Geolocalização não suportada por este navegador', life: 3000
                        });
                        clearInterval(this.getLocationInterval);
                        this.markerLocation = null;
                        this.locationOn = false;
                    }
                };

                // Chama a função pela primeira vez
                getLocation();

                // Verifica permissões do usuário e caso a localização for permitida, inicia a atualização a cada 2 segundos
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    if (result.state === 'granted') {
                        getLocation();
                        this.getLocationInterval = setInterval(getLocation, 2000);
                    } else if(result.state === 'denied'){
                        this.locationPermission = true;
                    }
                    result.onchange = () => {
                        if (result.state === 'granted' && !this.locationPermission) {
                            getLocation();
                            this.getLocationInterval = setInterval(getLocation, 2000);
                        } else if(result.state === 'denied'){
                            this.locationPermission = true;
                        }
                    }
                });
            },

            /**
             * Retorna true no primeiro zoom que tem tiles de qqr maquina
             *
             * **/
            hasTiles(payload) {
                for (let i = 0; i < payload.length; i++) {
                    for (let j = 0; j < payload[i].zooms.length; j++) {
                        let temp = payload[i].zooms[j].tiles.length;

                        if (temp > 0) {
                            return true
                        }
                    }
                }
                return false
            },
            getTilesList(dates, selectedVehicles) {
                this.isLoadingTiles = true;

                let payload = {
                    start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                    ids: selectedVehicles.map(v => v.id)
                };

                this.trackService.findByTilesByVIDAndDateV3(payload)
                    .then((response) => {

                        if (this.hasTiles(response)) {

                            this.$refs.chartsRightSide.saveStateChartsRealTime();

                            this.showHistoricalData = true;
                            this.cancelRequestsRealTime();
                            this.$refs.googleMapsRealTime.removeAllMarkers();

                            this.tilesData = response;
                            this.getHistoricalData(payload);
                            this.getEvents(payload);
                            this.getAppTilesList(dates, selectedVehicles);
                            this.getVelocityTilesList(dates, selectedVehicles);
                            this.getHumidityTilesList(dates, selectedVehicles);
                            this.trackOn = true;

                        } else {
                            this.$toast.add({
                                severity: 'info',
                                summary: 'Sem dados no período',
                                detail: 'Não foram localizados dados para o período informado',
                                life: 5000
                            });

                            this.isLoadingTiles = false;
                            this.isLoadingSensorData = false;
                            this.isLoadingTiles = false;
                        }
                    }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.isLoadingTiles = false;
                })
            },
            getAppTilesList(dates, selectedVehicles) {
                this.isLoadingApplicationTiles = true;

                let payload = {
                    start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                    ids: selectedVehicles.map(v => v.id)
                };

                this.trackService.findAppRateTiles(payload)
                    .then((response) => {
                        this.applicationOn = true;
                        this.speedOn = false;
                        this.humidityOn = false;
                        this.applicationTilesData = response;
                    }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.isLoadingApplicationTiles = false;
                })
            },
            getVelocityTilesList(dates, selectedVehicles) {
                this.isLoadingVelocityTiles = true;

                let payload = {
                    start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                    ids: selectedVehicles.map(v => v.id)
                };

                this.trackService.findSpeedTiles(payload)
                    .then((response) => {
                        this.speedTilesData = response;
                    }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.isLoadingVelocityTiles = false;
                })
            },
            getHumidityTilesList(dates, selectedVehicles) {
                this.isLoadingHumidityTiles = true;

                let payload = {
                    start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                    end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                    ids: selectedVehicles.map(v => v.id)
                };

                this.trackService.findHumidityTiles(payload)
                    .then((response) => {
                        this.humidityTilesData = response;
                    }).catch((error) => {
                    console.log(error)
                }).finally(() => {
                    this.isLoadingHumidityTiles = false;
                })
            },

            clearHistoricalData() {
                this.tilesData = [];
                this.applicationTilesData = [];
                this.speedTilesData = [];
                this.humidityTilesData = [];
                this.gaps = [];
                this.applicationOn = false;
                this.speedOn = false;
                this.humidityOn = false;
                this.showHistoricalData = false;
                this.operationalAlertsOn = false;
                this.machineAlertsOn = false;
                this.$refs.googleMapsRealTime.removeOperationalAlertsFromRealTime();
                this.$refs.googleMapsRealTime.removeMachineAlertsFromRealTime();
                this.$refs.googleMapsTypeControl.resetAlertsButton();
            },

            getEvents(values) {

                this.isLoadingEvents = true;
                this.eventsReport = [];
                const promises = [];
                let vm = this

                values.ids.forEach(function (vid) {
                    promises.push(vm.reportsService.getReportV2(vid, values.start_date, values.end_date));
                })

                Promise.all(promises)
                    .then((response) => {

                        let eventsResponse = [];
                        response.forEach(result => {
                            if (result.status === 'rejected') {
                                return;
                            }
                            eventsResponse.push(...result)
                        })

                        this.eventsReport = eventsResponse;

                        this.processEventsGaps();
                        this.processEventsReport();
                        this.populeCalcValues(eventsResponse);
                    }).catch((error) => {
                    console.log(error)
                    if (error.response && error.response.status === 404) {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Sem dados no periodo',
                            detail: 'Não foi possível localizar apontamentos do veículo no periodo informado',
                            life: 5000
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Operação falhou',
                            detail: 'Não foi possivel realizar a operação',
                            life: 5000
                        });
                    }
                }).finally(() => {
                    this.isLoadingEvents = false;
                })
            },
            processEventsGaps() {
                this.gaps = [];
                let gapsTemp = [];
                this.eventsReport.forEach(event => {
                    if (event.gap) {
                        gapsTemp.push(event.gap);
                    }
                })
                this.gaps = gapsTemp;
            },
            processEventsReport() {
                let eventsClusteredTemp = [];
                this.eventsReport.forEach(event => {
                    if (event.mode !== 2) {
                        return;
                    }

                    let eventClustered = eventsClusteredTemp.find(obj => {
                        return obj.reason_code === event.reason_code
                    });
                    if (eventClustered) {
                        eventClustered.total_time = eventClustered.total_time + event.time;
                        return;
                    }

                    eventsClusteredTemp.push({
                        reason_code: event.reason_code,
                        total_time: event.time,
                        reason_description: event.reason_description
                    })
                });

                this.eventsReportClustered = eventsClusteredTemp;
            },
            populeCalcValues(values) {
                let gasto_combustivel_total = 0;
                let gasto_combustivel_trabalho = 0;
                let gasto_combustivel_maquina_parada = 0;
                let gasto_combustivel_manobra = 0;
                let gasto_combustivel_estrada = 0;
                let gasto_combustivel_manutencao = 0;

                let tempo_atividade = 0;
                let tempo_trabalho = 0;
                let tempo_maquina_ociosa = 0;
                let tempo_manobra = 0;
                let tempo_deslocamento = 0;
                let tempo_desligado = 0;
                let tempo_aguardando_dados = 0;
                let tempo_nao_monitorado = 0;
                let tempo_manutencao = 0;

                let deslocamento_total = 0;
                let deslocamento_trabalho = 0;

                let total_area = 0;

                let maintenance_stop_time = 0;
                let total_time = 0;
                let engine_on_time = 0;

                values.forEach(event => {

                    total_time += event.time / 3600;
                    deslocamento_total += event.distance;
                    gasto_combustivel_total += event.consumption;
                    if (event.mode === 1) {
                        engine_on_time += event.time / 3600;
                        tempo_trabalho += event.time / 3600;
                        deslocamento_trabalho += event.distance;
                        gasto_combustivel_trabalho += event.consumption;
                    } else if (event.mode === 2) {
                        engine_on_time += event.time / 3600;
                        tempo_maquina_ociosa += event.time / 3600;
                        gasto_combustivel_maquina_parada += event.consumption;
                    } else if (event.mode === 3) {
                        engine_on_time += event.time / 3600;
                        tempo_manobra += event.time / 3600;
                        gasto_combustivel_manobra += event.consumption;
                    } else if (event.mode === 4) {
                        engine_on_time += event.time / 3600;
                        tempo_deslocamento += event.time / 3600;
                        gasto_combustivel_estrada += event.consumption;
                    } else if (event.mode === 5) {
                        tempo_desligado += event.time / 3600;
                    } else if (event.mode === 6) {
                        tempo_aguardando_dados += event.time / 3600;
                    } else if (event.mode === 7) {
                        tempo_nao_monitorado += event.time / 3600;
                    } else if (event.mode === 8) {
                        tempo_manutencao += event.time / 3600;
                        gasto_combustivel_manutencao += event.consumption;
                    }
                    total_area += event.area;

                    if (event.mode === 8) {
                        maintenance_stop_time += event.time / 3600;
                    }
                });

                let newCardsData = {};

                newCardsData.total_time = total_time;
                newCardsData._1000 = engine_on_time;
                newCardsData._1001 = tempo_trabalho;
                newCardsData._1002 = tempo_maquina_ociosa;
                newCardsData._1003 = tempo_manobra;
                newCardsData._1004 = tempo_deslocamento;
                newCardsData._1005 = deslocamento_total;
                newCardsData._1006 = deslocamento_trabalho;
                newCardsData._1009 = gasto_combustivel_total;
                newCardsData._1010 = gasto_combustivel_trabalho;
                newCardsData._1011 = gasto_combustivel_maquina_parada;
                newCardsData._1012 = gasto_combustivel_manobra;
                newCardsData._1013 = gasto_combustivel_estrada;
                newCardsData._1035 = total_area;
                newCardsData.maintenance_stop_time = maintenance_stop_time;
                newCardsData.total_time = total_time;

                this.cardsData = newCardsData;

                let gasto_combustivel_trabalho_chart = Math.round((gasto_combustivel_trabalho / gasto_combustivel_total) * 100);
                let gasto_combustivel_maquina_parada_chart = Math.round((gasto_combustivel_maquina_parada / gasto_combustivel_total) * 100);
                let gasto_combustivel_manobra_chart = Math.round((gasto_combustivel_manobra / gasto_combustivel_total) * 100);
                let gasto_combustivel_estrada_chart = Math.round((gasto_combustivel_estrada / gasto_combustivel_total) * 100);
                let gasto_combustivel_manutencao_chart = Math.round((gasto_combustivel_manutencao / gasto_combustivel_total) * 100);


                this.historicalPieChartsData.gasto_combustivel_series = [];
                this.historicalPieChartsData.gasto_combustivel_series.push({
                    name: 'Ocioso',
                    value: gasto_combustivel_maquina_parada_chart,
                    itemStyle: {color: '#d62728'},
                    rawValue: gasto_combustivel_maquina_parada
                });
                this.historicalPieChartsData.gasto_combustivel_series.push({
                    name: 'Manobrando',
                    value: gasto_combustivel_manobra_chart,
                    itemStyle: {color: '#ff7e0e'},
                    rawValue: gasto_combustivel_manobra
                });
                this.historicalPieChartsData.gasto_combustivel_series.push({
                    name: 'Produzindo',
                    value: gasto_combustivel_trabalho_chart,
                    itemStyle: {color: '#2ca02c'},
                    rawValue: gasto_combustivel_trabalho
                });
                this.historicalPieChartsData.gasto_combustivel_series.push({
                    name: 'Deslocando',
                    value: gasto_combustivel_estrada_chart,
                    itemStyle: {color: '#1f76b4'},
                    rawValue: gasto_combustivel_estrada
                });
                this.historicalPieChartsData.gasto_combustivel_series.push({
                    name: 'Manutenção',
                    value: gasto_combustivel_manutencao_chart,
                    itemStyle: {color: '#B507A7'},
                    rawValue: gasto_combustivel_manutencao
                });

                let data = tempo_trabalho + tempo_maquina_ociosa + tempo_manobra + tempo_deslocamento + tempo_desligado + tempo_aguardando_dados + tempo_nao_monitorado + tempo_manutencao
                let tempo_trabalho_chart = this.formatValue(((tempo_trabalho / data) * 100) / this.selectedVehicles.length);
                let tempo_maquina_parada_chart = this.formatValue(((tempo_maquina_ociosa / data) * 100) / this.selectedVehicles.length);
                let tempo_manobra_chart = this.formatValue(((tempo_manobra / data) * 100) / this.selectedVehicles.length);
                let tempo_deslocamento_chart = this.formatValue(((tempo_deslocamento / data) * 100) / this.selectedVehicles.length);
                let tempo_desligado_chart = this.formatValue(((tempo_desligado / data) * 100) / this.selectedVehicles.length);
                let tempo_aguardando_dados_chart = this.formatValue(((tempo_aguardando_dados / data) * 100) / this.selectedVehicles.length);
                let tempo_nao_monitorado_chart = this.formatValue(((tempo_nao_monitorado / data) * 100) / this.selectedVehicles.length);
                let tempo_manutencao_chart = this.formatValue(((tempo_manutencao / data) * 100) / this.selectedVehicles.length);

                this.historicalPieChartsData.atividade_series = [];
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Ocioso',
                    value: tempo_maquina_parada_chart,
                    itemStyle: {color: '#d62728'},
                    rawValue: tempo_maquina_ociosa
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Manobrando',
                    value: tempo_manobra_chart,
                    itemStyle: {color: '#ff7e0e'},
                    rawValue: tempo_manobra
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Produzindo',
                    value: tempo_trabalho_chart,
                    itemStyle: {color: '#2ca02c'},
                    rawValue: tempo_trabalho
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Deslocando',
                    value: tempo_deslocamento_chart,
                    itemStyle: {color: '#1f76b4'},
                    rawValue: tempo_deslocamento
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Desligado',
                    value: tempo_desligado_chart,
                    itemStyle: {color: '#5E3F20'},
                    rawValue: tempo_desligado
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Aguardando Dados',
                    value: tempo_aguardando_dados_chart,
                    itemStyle: {color: '#FFFF33'},
                    rawValue: tempo_aguardando_dados
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Não Monitorado',
                    value: tempo_nao_monitorado_chart,
                    itemStyle: {color: '#797979'},
                    rawValue: tempo_nao_monitorado
                });
                this.historicalPieChartsData.atividade_series.push({
                    name: 'Manutenção',
                    value: tempo_manutencao_chart,
                    itemStyle: {color: '#B507A7'},
                    rawValue: tempo_manutencao
                });

                this.handleChangeWorkType();
            },
            formatValue(value) {
                return parseFloat((value).toFixed(2))
            },
            addFieldToChartArray(value, name, color) {
                if (value > 0) {
                    this.chartArrayToPush.push({name: name, value: value, itemStyle: {color: color}});
                }
            },
            handleChangeWorkType() {
                this.cards = [];

                if (this.optionsSelectWorkTypeSelected.key === 'TOTAL') {

                    let rendimento = this.cardsData._1009 / this.cardsData._1035;
                    let consumo = this.cardsData._1009 / this.cardsData._1000;
                    let consumoKmH = this.cardsData._1005 / this.cardsData._1009;
                    let velocidade = this.cardsData._1005 / this.cardsData._1000;
                    let disponibilidadeMecanica = ((this.cardsData.total_time - this.cardsData.maintenance_stop_time) / this.cardsData.total_time) * 100;
                    let eficiencia = ((this.cardsData._1001 + this.cardsData._1003)/ ((this.cardsData.total_time) * (disponibilidadeMecanica/100))) * 100;
                    let produtividade = this.cardsData._1035 / this.cardsData._1000;

                    this.pushCard({title: "Área", value: this.checkValue(this.cardsData._1035, 'ha'), tooltip: 'Área produzida em modo Trabalhando'});
                    this.pushCard({title: "Combustível", value: this.checkValue(this.cardsData._1009, 'L'), tooltip: 'Combustível total gasto'});
                    this.pushCard({title: "Tempo", value: this.checkValue(this.cardsData.total_time, 'h'), tooltip: 'Tempo total'});
                    this.pushCard({title: "Motor Ligado", value: this.checkValue(this.cardsData._1000, 'h'), tooltip: 'Tempo de motor ligado fora de manutenção'});
                    this.pushCard({title: "Distância", value: this.checkValue(this.cardsData._1005, 'km'), tooltip: 'Distância total percorrida'});
                    this.pushCard({title: "Velocidade", value: this.checkValue(velocidade, 'km/h'), tooltip: 'Distância total percorrida sobre tempo total de motor ligado'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumoKmH, 'km/L'), tooltip: 'Distância total percorrida sobre consumo total de combustível'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumo, 'L/h'), tooltip: 'Consumo total de combustível sobre tempo total de motor ligado'});
                    this.pushCard({title: "Produtividade", value: this.checkValue(produtividade, 'ha/h'), tooltip: 'Área produzida em modo Trabalhando sobre tempo total de motor ligado'});
                    this.pushCard({title: "Rendimento", value: this.checkValue(rendimento, 'L/ha'), tooltip: 'Consumo total de combustível sobre área produzida em modo Trabalhando'});
                    this.pushCard({title: "Disponibilidade", value: this.checkValue(disponibilidadeMecanica, '%'), tooltip: 'Porcentagem do tempo pesquisado fora de uma parada de manutenção'});
                    this.pushCard({title: "Eficiência", value: this.checkValue(eficiencia, '%'), tooltip: 'Porcentagem de tempo em modo Trabalhando e Manibrando em relação ao tempo total disponível mecanicamente'});

                } else if (this.optionsSelectWorkTypeSelected.key === 'TRABALHANDO') {

                    let velocidade = this.cardsData._1006 / this.cardsData._1001;
                    let consumo = this.cardsData._1010 / this.cardsData._1001;
                    let produtividade = this.cardsData._1035 / this.cardsData._1001;
                    let rendimento = this.cardsData._1010 / this.cardsData._1035;

                    this.pushCard({title: "Área", value: this.checkValue(this.cardsData._1035, 'ha'), tooltip: 'Área produzida em modo Trabalhando'});
                    this.pushCard({title: "Combustível", value: this.checkValue(this.cardsData._1010, 'L'), tooltip: 'Combustível gasto em modo Trabalhando'});
                    this.pushCard({title: "Tempo", value: this.checkValue(this.cardsData._1001, 'h'), tooltip: 'Tempo de motor ligado em modo Trabalhando'});
                    this.pushCard({title: "Velocidade", value: this.checkValue(velocidade, 'km/h'), tooltip: 'Distância percorrida em modo Trabalhando sobre tempo de motor ligado em modo Trabalhando'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumo, 'L/h'), tooltip: 'Consumo de combustível em modo Trabalhando sobre tempo de motor ligado em modo Trabalhando'});
                    this.pushCard({title: "Produtividade", value: this.checkValue(produtividade, 'ha/h'), tooltip: 'Área produzida em modo Trabalhando sobre tempo de motor ligado em modo Trabalhando'});
                    this.pushCard({title: "Rendimento", value: this.checkValue(rendimento, 'L/ha'), tooltip: 'Consumo de combustível em modo Trabalhando sobre área produzida em modo Trabalhando'});


                } else if (this.optionsSelectWorkTypeSelected.key === 'OCIOSO') {

                    let consumo = this.cardsData._1011 / this.cardsData._1002;

                    this.pushCard({title: "Combustível", value: this.checkValue(this.cardsData._1011, 'L'), tooltip: 'Combustível gasto em modo Ocioso'});
                    this.pushCard({title: "Tempo", value: this.checkValue(this.cardsData._1002, 'h'), tooltip: 'Tempo de motor ligado em modo Ocioso'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumo, 'L/h'), tooltip: 'Consumo de combustível em modo Ocioso sobre tempo de motor ligado em modo Ocioso'});


                } else if (this.optionsSelectWorkTypeSelected.key === 'MANOBRANDO') {

                    let consumo = this.cardsData._1012 / this.cardsData._1003;

                    this.pushCard({title: "Combustível", value: this.checkValue(this.cardsData._1012, 'L'), tooltip: 'Combustível gasto em modo Manobrando'});
                    this.pushCard({title: "Tempo", value: this.checkValue(this.cardsData._1003, 'h'), tooltip: 'Tempo de motor ligado em modo Manobrando'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumo, 'L/h'), tooltip: 'Consumo de combustível em modo Manobrando sobre tempo de motor ligado em modo Manobrando'});

                } else if (this.optionsSelectWorkTypeSelected.key === 'DESLOCANDO') {

                    let consumo = this.cardsData._1013 / this.cardsData._1004;

                    this.pushCard({title: "Combustível", value: this.checkValue(this.cardsData._1013, 'L'), tooltip: 'Combustível gasto em modo Deslocando'});
                    this.pushCard({title: "Tempo", value: this.checkValue(this.cardsData._1004, 'h'), tooltip: 'Tempo de motor ligado em modo Deslocando'});
                    this.pushCard({title: "Consumo", value: this.checkValue(consumo, 'L/h'), tooltip: 'Consumo de combustível em modo Deslocando sobre tempo de motor ligado em modo Deslocando'});

                }
            },
            checkValue(val, medida) {
                if (!val || isNaN(val) || !isFinite(val) || val < 0) {
                    return 'N/D';
                } else {
                    return val.toFixed(2) + ' ' + medida;
                }
            },
            pushCard(cardContent) {
                if (cardContent.value !== 'N/D') {
                    this.cards.push(cardContent);
                }
            },
            getHistoricalData(payload) {
                this.isLoadingSensorData = true;
                this.vehiclesService.getHistoricalData(payload)
                    .then((response) => {
                        this.historicalChartsData = response;
                    }).catch((error) => {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Operação falhou',
                        detail: 'Não foi possivel realizar a operação',
                        life: 5000
                    });
                    console.log(error);
                }).finally(() => {
                    this.isLoadingSensorData = false;
                });
            },
            handleZoomChanged(payload) {
                this.mapBoundsAndZoom = payload;

                if (!this.showFields) {
                    return;
                }

                if (this.useFieldsTiles && this.fieldsOverlayActive === this.fieldsOverlayActiveOptions.DECK) {
                    // altera para os tiles
                    this.fieldsOverlayActive = this.fieldsOverlayActiveOptions.TILES;
                    this.getFieldsTiles();
                    this.$refs.googleMapsRealTime.removeFieldsFromRealTime();
                } else if (!this.useFieldsTiles && this.fieldsOverlayActive === this.fieldsOverlayActiveOptions.TILES) {
                    // alterar para o deck
                    this.fieldsOverlayActive = this.fieldsOverlayActiveOptions.DECK;
                    this.getFieldsDeck();
                    this.$refs.googleMapsRealTime.removeFieldsTiles();
                }
            },
            handleBoundsChanged(payload) {

                this.mapBoundsAndZoom = payload;

                if (!this.showFields) {
                    return;
                }

                //so preicisa gerenciar do deck pois os tiles são gerenciados pelo proprio gmaps
                if (this.fieldsOverlayActive === this.fieldsOverlayActiveOptions.DECK) {
                    this.getFieldsDeck();
                }
            },
        },
        components: {
            AppFilterBar, Splitpanes, Pane, AppGoogleMapsRealTime, Button,
            AppButton, AppVehiclesSelectVehicles, AppDateTimeControlBar,
            Dropdown, AppGoogleMapsLegend, MultiSelect, AppChartsRightSide, AppCards, Dialog,
            AppGoogleMapsTypeControl, AppGoogleMapsWeatherLegend, AppVehiclesStatusControl, AppVehiclesNewSelectionDialog,
            AppRealTimeReport
        },
        computed: {
            useFieldsTiles: function () {
                return this.mapBoundsAndZoom.zoom <= 14;
            },
            selectedOrganizationIdentification: function () {
                return this.selectedOrganizations[0] ? this.selectedOrganizations[0].id : 'undefined';
            },
            selectedOrganizationId: function () {
                return this.selectedOrganizations[0] ? this.selectedOrganizations[0].id : 'undefined';
            },
            organizationSelected: function () {
                if (!this.selectedOrganizations || this.selectedOrganizations.length === 0) {
                    return null
                }
                return this.selectedOrganizations[0].document_number;
            },
            isLoadingHistoricalData: function () {

                if (this.isLoadingTiles) {
                    return true;
                }

                if (this.isLoadingSensorData) {
                    return true;
                }

                if (this.isLoadingEvents) {
                    return true;
                }

                if (this.isLoadingApplicationTiles) {
                    return true;
                }

                if (this.isLoadingVelocityTiles) {
                    return true;
                }

                if (this.isLoadingHumidityTiles) {
                    return true;
                }

                return false;
            },
            isScreenMobile: function() {
                return window.screen.width <= 1024;
            }
        },
        watch: {
            selectedVehicles: function (val) {
                if (val && val.length > 0 && !this.showPanelStatus) {
                    this.showRightPaneCharts = true;
                } else {
                    //nenhuma maquina selecionada fecha o painel lateral do tempo real
                    this.showRightPaneCharts = false;
                }
            },
            selectedOrganizations: function(val){
                if(val.length == 0 && this.showPanelStatus){
                    this.handleShowDetails();
                }
            },
            vehicles: function (val) {
                if(val){
                    this.displayIdVehicles = [];
                    val.forEach(element => {
                        this.displayIdVehicles.push({ display_id: element.display_id, id: element.id });
                    });
                }
            }
        },
        beforeDestroy: function () {
            this.cancelRequestsRealTime();
            window.removeEventListener('keydown', this.keyF11);
        }
    }
</script>

<style scoped lang="scss">

    //toda a altura subtraindo o menu superior
    .splitpanes-height {
        height: calc(100vh - 81px)
    }

    .splitpanes--vertical > .splitpanes__splitter {
        width: 3px !important;
    }

    .kt-portlet {
        margin-bottom: 0 !important;
    }

    .font-select-option {
        font-size: 15px;
    }

    .width-auto {
        width: auto !important;
    }

    .width-auto-100 {
        width: 100%;
    }

    .chart {
        height: calc(25vh - 53px);
        width: 100%;
    }

    .chart-pie {
        height: calc(50vh - 73px);
        width: 100%;
    }

    .cards-timeline {
        height: calc(100vh - 113px);
        width: 100%;
    }

    .wraper .google-maps {
        height: calc(100vh - 81px) !important;
    }

    .font-select-option {
        font-size: 15px;
    }

    .auto-center {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    select:disabled {
        opacity: 0.6;
    }

    .multiselect-status-icon {
        padding: .25rem .5rem;
        height: 4vh;
    }

    .line {
        background-color: white;
        height: 35px;
        width: 35px;
        border-radius: 5px;
    }

    .item img {
        max-width: 35px;
    }

    .active{
        background-color: #1B6AEC;
    }

    .item-active img{
        max-width: 35px;
        filter: brightness(0%) invert(100%);
    }

    .position-bottom{
        position: absolute;
        z-index: 5;
        bottom: -25px;
        width: 95%;
        margin: 0 auto;
    }

    .height-maps{
        height: 65vh;
    }

    .height-maps-open{
        height: calc(100vh - 110px);
    }

    .height-maps-close{
        height: 65vh;
    }

    .active-panel{
        background-color: black;
    }

    .icon-eye{
        font-size: 22px;
        margin-top: 6px;
        margin-left: 6px;
    }

    @media(min-width: 1025px){
        .active:hover{
            background-color: #1757bd;
        }

        .icon-eye:hover{
            filter: brightness(80%);
        }

        .item img:hover {
            filter: brightness(80%);
        }
    }

    @media(max-width: 1024px){
        .position-bottom{
            bottom: 10px;
        }
    }
</style>

<template>
    <div class="col-3" style="min-height: 100%;position: relative">
        <div class="pr-2 mt-2" style="width: 100%;">
                                <span class="pi pi-times-circle" @click="closeFieldEditClicked"
                                      style="font-size: 1.8rem;color: #393939;float: right;cursor: pointer;"/>
        </div>
        <div class="mt-4"
             style="font-size:20px;font-weight: 800;color: black;display: flex; justify-content: center">
            {{ componentTitle }}
        </div>
        <div class="row mx-5 mt-4">
            <div class="col" style="display:flex;justify-content: center">
                <app-button :type="drawOrDiscardType" style="width: 70%;height: 40px" class="center"
                            @handleClick="drawOrDiscardClicked" :disabled="drawing">
                    <span :class="drawOrDiscardClass"/>
                    <div>
                        {{ drawOrDiscardText }}
                    </div>
                </app-button>
            </div>
        </div>
        <div style="width: 100%;" class="mt-3 pl-3 pr-3">
            <div class="p-field p-col-12 p-md-3" style="width: 100%">
                <label class="mb-0">Nome</label>
                <div>
                    <InputText :id="getId" v-model="fieldName" placeholder="Local 01" style="width: 100%"
                               :style="{displayNameHelp: 'opa'}"/>
                    <small v-if="displayNameHelp" id="field-name-help" style="color: red">Nome é obrigatório.</small>
                </div>
            </div>

            <div class="p-field p-col-12 p-md-3">
                <label class="mb-0">Tipo de Cerca Georreferenciada</label>
                <Dropdown v-model="fieldType" :options="fieldTypes" placeholder="Selecione" style="width: 100%"
                          class="p-column-filter" :showClear="true" optionLabel="name" optionValue="code">
                    <template #option="slotProps">
                        <span>{{slotProps.option.name}}</span>
                    </template>
                </Dropdown>
            </div>

            <div class="p-field p-col-12 p-md-3">
                <label class="mb-0">Cor</label>
                <div style="display: flex;">
                    <ColorPicker v-model="fieldColor" style="padding-right: 5px;"/>
                </div>
                <label class="mb-0 mt-3">Sugestões</label>
                <div style="display: flex; flex-wrap: wrap; position: relative; right: 7px;" class="mb-3">
                    <div v-for="color in colorArray" :key="color" :style="{ backgroundColor: '#' + color }" class="colorSuggestions" @click="colorClicked(color)"></div>
                </div>
            </div>
            <div v-show="fieldDrawn"
                 class="mt-1 center" style="color: #5e5e5e; font-size: 17px;font-weight: 600;width: 100%">
                {{ shapeArea }} ha
            </div>
            <div style="display: flex;justify-content: center;align-items: center;" :class="{'margin-bottom-canvas': is_col_12}" class="p-0">
                <canvas id="canvas" width=150 height=120 ref="myCanvas" class="mt-2"/>
            </div>
        </div>

        <div class="pl-3 pr-3" style="position: absolute; bottom: -50px;width: 97%;">
            <div class="row ">
                <div class="col center p-0">
                    <app-button type="primary" :disabled="!fieldDrawn" style="width:70%"
                                @handleClick="saveFieldClicked">
                        <span class="pi pi-save mr-2"/>
                        Salvar
                    </app-button>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="showEditFieldDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span>Deseja sair do editor de locais? As alterações não salvas serão
                    descartadas.</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="showEditFieldDialog = false"/>
                <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="closeFieldEdit"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="showDiscardShapeDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span>Deseja descartar atual formato do local?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="showDiscardShapeDialog = false"/>
                <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="discardShape"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="showSaveFieldDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 15px; margin-right: 10px"/>
                <span>Deseja salvar o talhão?</span>
            </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="showSaveFieldDialog = false"/>
                <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="saveField"/>
            </template>
        </Dialog>
        <Dialog :visible.sync="showDrawingModeDialog" :style="{width: '450px'}" header="O que deseja desenhar?" :modal="true">
            <div class="confirmation-content">
                <div style="display: flex">
                    <app-button type="primary" style="width: 100%;height: 47px;font-size: 18px" @handleClick='drawPolygon'>
                        <span class="pi pi-pencil pr-2"></span>
                        Polígono
                    </app-button>
                    <app-button type="secondary" style="width: 100%;height: 47px;font-size: 18px;margin-left: 5px" @handleClick='drawPolyline'>
                        <span class="pi pi-pencil pr-2"></span>
                        Linha
                    </app-button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>

import AppButton from "@/components/common/AppButton";

import Dialog from "primevue/dialog"
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ColorPicker from 'primevue/colorpicker';

import * as jsts from 'jsts';
import Dropdown from "primevue/dropdown";

export default {
    mounted() {
        this.is_col_12 = this.isScreenMobile;
        this.fieldType = 'UNDEFINED';
    },
    data() {
        return {
            is_col_12: false,
            fieldColor: 'FFFFFF',
            fieldName: null,

            shapeArea: null,
            shape: null,
            colorArray: [
                '000000', '00007F', '0336AD', '007F00', '007F7F', '007FFF', '00FF00', '00FF7F', '00FFFF',
                '7F0000', '7F007F', '7F00FF', '7F7F00', '7F7F7F', '7F7FFF', '7FFF00', '7FFF7F', '7FFFFF',
                'FF0000', 'FF007F', 'FF00FF', 'FF7F00', 'FF7F7F', 'FF7FFF', 'FFFF00', 'FFFF7F', 'FFFFFF'
            ],

            showEditFieldDialog: false,
            showDiscardShapeDialog: false,
            showSaveFieldDialog: false,
            showDrawingModeDialog: false,

            displayNameHelp: false,
            drawing: false,
            fieldType: 'UNDEFINED',
            fieldTypes: [{'name': 'Não Definido', 'code': 'UNDEFINED'},
                {'name': 'Produtivo', 'code': 'PRODUCTIVE'},
                {'name': 'Não Produtivo', 'code': 'UNPRODUCTIVE'},
                {'name': 'Manutenção', 'code': 'MAINTENANCE'},
                {'name': 'Abastecimento', 'code': 'REFUEL'}]

        }
    },
    props: {
        draw_field: {
            type: Boolean
        },
        field_to_edit: {
            type: Object,
        },
    },
    methods: {
        colorClicked(val) {
            this.fieldColor = val;
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        saveFieldClicked() {
            if (!this.fieldName) {
                this.displayNameHelp = true;
                return;
            }

            if (this.shapeArea > 5000) {
                this.$toast.add({severity: 'info', summary: 'Talhão não pode ter mais que 5000 ha', life: 3000});
                return;
            }

            if (!this.isValidGeom()) {
                this.$toast.add({severity: 'error', summary: 'Geomtria desenhada inválida. Desenhe novamente', life: 3000});
                return;
            }

            this.displayNameHelp = false;
            this.showSaveFieldDialog = true;
        },
        isValidGeom() {
            let reader = new jsts.io.WKTReader();
            let jstsGeom = reader.read( this.getCoordinatesStringFormat(this.shape));
            return jstsGeom.isValid();
        },
        saveField() {
            this.$emit('saveField', {name: this.fieldName, color: this.fieldColor,
                coordinates: this.getCoordinatesStringFormat(this.shape), area: this.shapeArea, type: this.fieldType});
            this.clearData();
            this.showSaveFieldDialog = false;
        },
        discardShapeClicked() {
            this.showDiscardShapeDialog = true;
        },
        discardShape() {
            this.shapeArea = null;
            this.shape = null;
            this.clearCanvas();
            this.showDiscardShapeDialog = false;
            this.$emit('discardShape');
        },
        closeFieldEditClicked() {
            this.showEditFieldDialog = true;
        },
        closeFieldEdit() {
            this.clearData();
            this.showEditFieldDialog = false;
            this.$emit('closeFieldEdit');
        },
        clearData() {
            this.fieldName = null;
            this.shapeArea = null;
            this.shape = null;
            this.displayNameHelp = false;
            this.fieldType = 'UNDEFINED';
            this.clearCanvas();
        },
        drawPolygon() {
            this.showDrawingModeDialog = false;

            this.$emit('drawFieldClicked', {mode: 'polygon'});
            this.drawing = true;
            this.$toast.add({severity: 'info', summary: 'Selecione os vértices do polígono no mapa', life: 3000});
        },
        drawPolyline() {
            this.showDrawingModeDialog = false;

            this.$emit('drawFieldClicked', {mode: 'polyline'});
            this.drawing = true;
            this.$toast.add({severity: 'info', summary: 'Clique no último ponto para finalizar a linha', life: 3000});
        },
        drawOrDiscardClicked() {
            if (this.shapeArea) {
                this.discardShapeClicked();
                return;
            }
            this.showDrawingModeDialog = true;
        },
        getCoordinatesFromShape(shape) {
            let paths = shape.getPaths().getArray();

            if (paths.length === 0) {
                return;
            }

            let pathArray = [];
            let points = paths[0].getArray();
            let firstPoint = false;

            for (let point of points) {
                if (firstPoint === false) {
                    firstPoint = point;
                }

                pathArray.push([point.lng().toFixed(6), point.lat().toFixed(6)])
            }

            pathArray.push([firstPoint.lng().toFixed(6), firstPoint.lat().toFixed(6)]);

            return pathArray;
        },
        getCoordinatesStringFormat(shape) {
            let pathArray = this.getCoordinatesFromShape(shape);
            let coordString = "POLYGON ((";

            pathArray.forEach(path => {
                coordString += path[0] + " " + path[1] + ", ";
            })
            coordString = coordString.slice(0, -2) + "))"

            return coordString;
        },
        clearCanvas() {
            let canvas = document.getElementById('canvas');
            canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
        },
        drawShape(coordinates) {
            this.clearCanvas();

            let canvas = document.getElementById('canvas');
            let ctx = canvas.getContext('2d');

            let minX, minY, maxX, maxY;
            coordinates.forEach((p, i) => {
                if (i === 0) {
                    minX = maxX = p[0];
                    minY = maxY = p[1];
                } else {
                    minX = Math.min(p[0], minX);
                    minY = Math.min(p[1], minY);
                    maxX = Math.max(p[0], maxX);
                    maxY = Math.max(p[1], maxY);
                }
            });

            const mapWidth = maxX - minX;
            const mapHeight = maxY - minY;
            const mapCenterX = (maxX + minX) / 2;
            const mapCenterY = (maxY + minY) / 2;


            const scale = Math.min((canvas.width - 5) / mapWidth, (canvas.height - 5) / mapHeight);

            ctx.setTransform(1, 0, 0, -1, -1, canvas.height);

            ctx.beginPath();
            coordinates.forEach(p => {
                ctx.lineTo(
                    (p[0] - mapCenterX) * scale + canvas.width / 2,
                    (p[1] - mapCenterY) * scale + canvas.height / 2
                );
            });

            ctx.stroke();
        },
        async updateDrawnShape(shape) {
            this.shape = shape;
            this.shapeArea = shape.area;
            await this.sleep(1);
            this.drawShape(this.getCoordinatesFromShape(shape));
        },
        getTypeFromOptions(type) {
            let typeToReturn = 'UNDEFINED';
            this.fieldTypes.forEach(eachType => {
                if (eachType.code === type) {
                    typeToReturn = eachType.code
                }
            })

            return typeToReturn;
        }
    },
    watch: {
        fieldColor: function(val){
            if (val) {
                this.$emit('colorPolygon', val);
            }
        },
        field_to_edit: function (val) {
            this.fieldType = 'UNDEFINED'
            if (val) {
                this.fieldName = val.name;
                this.fieldColor = val.color;
                this.fieldType = this.getTypeFromOptions(val.type);
            }
        },
        shapeArea: function (val) {
            if (val) {
                this.drawing = false
            }
        },
        fieldName: function (val) {
            if (this.fieldName) {
                this.fieldName = val.replaceAll(',', '');
            }
        },

    },
    computed: {
        componentTitle() {
            return this.field_to_edit ? 'Editar Local' : 'Novo Local';
        },
        getId() {
            if (this.displayNameHelp) {
                return 'field-name';
            }
            return '';
        },
        drawOrDiscardText() {
            return this.shapeArea ? 'Descartar Desenho' : 'Desenhar';
        },
        drawOrDiscardClass() {
            return this.shapeArea ? 'mr-2 pi pi-trash' : 'mr-2 pi pi-pencil';
        },
        drawOrDiscardType() {
            return this.shapeArea ? 'secondary' : 'primary';
        },
        fieldDrawn() {
            return this.shape;
        },
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        }
    },
    components: {
        Dropdown, AppButton, Dialog, InputText, Button, ColorPicker
    }
}

</script>

<style scoped lang="scss">

@import "src/assets/styles/primevue";

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-inputtext#field-name {
    border: 1px solid red !important;
}

.colorSuggestions{
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 7px;
    border: solid 0.5px rgb(207, 207, 207);
}

.colorSuggestions:hover{
    cursor: pointer;
    border: solid 1px #2196F3;
}

.margin-bottom-canvas{
    margin-bottom: 60px;
}

</style>

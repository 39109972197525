<template>
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <!--begin::Portlet-->
            <div class="kt-portlet">
                <div class="kt-portlet__head no-border-radius">
                    <div class="kt-portlet__head-label">
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <app-button type="secondary" @handleClick="back">
                            <i class="glyphicons glyphicons-arrow-left"></i>{{ $t('back') }}
                        </app-button>
                    </div>
                </div>
                <!--begin::Form-->
                <div class="kt-form shadow-lg pl-3 pr-3 bg-white mt-3 mt-md-5" style="border-radius: 10px;">
                    <app-vehicles-form :vehicle_edit="vehicle_edit" @handleSalve="saveVehicle"/>
                </div>
                <!--end::Form-->
            </div>
            <!--end::Portlet-->
        </div>
</template>

<script>

    import axios from '../../../axios/axios-auth'
    import {mapGetters} from 'vuex';
    import {router} from '../../../router'

    import AppVehiclesForm from "./AppVehiclesForm";
    import AppButton from "../../common/AppButton";

    import VehiclesService from "../../../services/VehiclesService";

    export default {
        mounted() {
            this.vehiclesService = new VehiclesService();

            if (this.$route.params.id) {
                this.getVehicleDetails();
            }
        },
        data() {
            return {
                vehiclesService: null,
                vehicle_identification: this.$route.params.id,
                vehicle_edit: {}
            }
        },
        components: {
            AppVehiclesForm, AppButton
        },
        methods: {
            getVehicleDetails() {
                let vm = this;
                this.vehiclesService.getVehicleDetails(this.vehicle_identification)
                    .then( (response) => {
                            vm.vehicle_edit = response;
                    }).catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403) {
                            vm.$toast.add({
                                severity: 'error',
                                summary: 'Você não tem acesso a esssa funcionalidade',
                                life: 5000
                            });
                        } else {
                            vm.$toast.add({
                                severity: 'error',
                                summary: 'Não foi possivel completar a operação, tente novamente',
                                life: 5000
                            });
                        }
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação, tente novamente',
                            life: 5000
                        });
                    }
                })
            },
            saveVehicle(value) {
                let vm = this;

                value.organization_id = this.vehicle_edit.organization_id;
                value.model = value.model.value;

                this.vehiclesService.patchV3(value.id, value)
                    .then(response => {
                        vm.$toast.add({
                            severity: 'success',
                            summary: "Veículo atualizado com sucesso",
                            life: 5000
                        });
                        router.push({name: 'vehicles'})
                    }).catch(error => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            vm.$toast.add({
                                severity: 'error',
                                summary: 'Você não tem acesso a esssa funcionalidade',
                                life: 5000
                            });
                        } else {
                            vm.$toast.add({
                                severity: 'error',
                                summary: 'Não foi possivel completar a operação, tente novamente',
                                life: 5000
                            });
                        }
                    } else {
                        vm.$toast.add({
                            severity: 'error',
                            summary: 'Não foi possivel completar a operação, tente novamente',
                            life: 5000
                        });
                    }
                });
            },
            back() {
                router.go(-1);
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentOrganization'
            ])
        },
    }
</script>

<style scoped>

    .kt-form{
        width: 35%;
        margin: 0 auto;
    }

    @media (max-width: 758px){
        .kt-form{
            width: 95%;
        }
    }

    @media (max-width: 1024px) and (min-width: 758px){
        .kt-form{
            width: 70%;
        }
    }
</style>

<template>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile" id="tableDiv">
            <div v-if="!showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :feature="'TRACK'"
                                :is_working_modes="true"
                                :show_vehicle_type="true"
                                :filter_button_is_loading="false"
                                ref="filterBar">
                    <template v-slot:slot-two>
                        <app-button type="secondary"
                                    class="auto-center"
                                    v-if="arrayVehicles.length > 0 && !isLoadingSensorData"
                                    style="background-color: #0D89EC;"
                                    @handleClick="exportCsv">
                            <i class="pi pi-external-link" />
                            Exportar
                        </app-button>
                    </template>
                </app-filter-bar>
            </div>
            <div v-if="showDialogFilters" class="kt-portlet__head kt-portlet__head--lg no-border-radius" style="justify-content: center;">
                <app-button v-show="showDialogFilters" type="primary" class="mx-1 auto-center"
                            @handleClick="displayMaximizable = true">
                    Filtrar
                </app-button>
                <app-button type="secondary"
                            class="auto-center"
                            v-if="arrayVehicles.length > 0 && !isLoadingSensorData"
                            style="background-color: #0D89EC;"
                            @handleClick="exportCsv">
                    <i class="pi pi-external-link" />
                    Exportar
                </app-button>
            </div>
            <Dialog header="Filtrar" :visible.sync="displayMaximizable" :containerStyle="{width: '85vw'}" :maximizable="true" :modal="true">
                <app-filter-bar @datesAndFiltersHandler="validateDataPreRequest"
                                :is_col_12="true"
                                :feature="'TRACK'"
                                :is_working_modes="true"
                                :filter_button_is_loading="false"
                                ref="filterBar"/>
            </Dialog>

            <div class="kt-portlet__body no-padding">
                <div v-if="arrayVehicles.length === 0 && !isLoadingSensorData" class="dashboard-container-no-data">
                    <div class="row no-data-container">
                        <h4>Sem dados para mostrar, faça a filtragem!</h4>
                    </div>
                </div>
                <div v-else-if="isLoadingSensorData" class="dashboard-container-loading">
                    <ProgressSpinner />
                </div>
                <div v-else>
                    <div class="width-vehicle-name">
                        <div v-for="(name, index) in arrayVehiclesName" :key="index" class="vehicle-name" :class="{'vehicle-name-active': index === vehicleSelected}" @click="changeVehicleSelected(index)">
                            <div v-if="index === 0" style="display: flex;">
                                <i class="pi pi-cog" style="font-size: 20px; position: relative; top: 3px;" />
                                <h4 class="f-bold ml-2">Configurações</h4>
                            </div>
                            <h4 v-else class="f-bold">{{ name }}</h4>
                        </div>
                    </div>
                    <div :class="{'height-info-config': vehicleSelected === 0, 'height-info' : vehicleSelected > 1, 'height-info-total' : vehicleSelected === 1}" >
                        <AppWorkingModesInfoVehicle
                            v-if="vehicleSelected > 0"
                            :item="getItemByArrayVehicles"
                            :showFilterShiftProp="showFilterShiftProp"
                            :workingModeMoney="workingModeMoney"
                            :idleModeMoney="idleModeMoney"
                            :maneuveringModeMoney="maneuveringModeMoney"
                            :travelingModeMoney="travelingModeMoney"
                            :offModeMoney="offModeMoney"
                            :waitingDataModeMoney="waitingDataModeMoney"
                            :maintenanceModeMoney="maintenanceModeMoney"
                            :consumptionMoney="consumptionMoney"
                            :showFormatHour="showFormatHour"
                        />
                        <div v-else class="container-config">
                            <div class="container-config-first">
                                <div class="container-costs">
                                    <div style="display: flex; justify-content: center; align-items: center; text-align: center; width: 100%; color: #696969; margin-bottom: 0px;">
                                        <div class="icon-RS">R$</div>
                                        <h4 class="f-bold">CUSTOS</h4>
                                    </div>
                                    <h5 style="text-align: center; width: 100%; color: #696969;">
                                        Insira os custos por modo de trabalho
                                    </h5>

                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].workingSeconds != 0">
                                        <h5 style="color: #696969;">Trabalhando</h5>
                                        <div style="width: 100px; height: 3px; background-color: #2CA02C; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="workingModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].maneuveringSeconds != 0">
                                        <h5 style="color: #696969;">Manobrando</h5>
                                        <div style="width: 100px; height: 3px; background-color: #FFAA00; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="maneuveringModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].travelingSeconds != 0">
                                        <h5 style="color: #696969;">Deslocando</h5>
                                        <div style="width: 100px; height: 3px; background-color: #1F76B4; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="travelingModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].idleSeconds != 0">
                                        <h5 style="color: #696969;">Ocioso</h5>
                                        <div style="width: 100px; height: 3px; background-color: #D62728; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="idleModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].offSeconds != 0">
                                        <h5 style="color: #696969;">Desligado</h5>
                                        <div style="width: 100px; height: 3px; background-color: #5E3F20; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="offModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].waitingSeconds != 0">
                                        <h5 style="color: #696969;">Aguardando Dados</h5>
                                        <div style="width: 100px; height: 3px; background-color: #FFFF33; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="waitingDataModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;" v-if="arrayVehicles[1].maintenanceSeconds != 0">
                                        <h5 style="color: #696969;">Manutenção</h5>
                                        <div style="width: 100px; height: 3px; background-color: #b507a7; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="maintenanceModeMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                    <div class="input-money" style="margin: 10px 10px;">
                                        <h5 style="color: #696969;">Consumo</h5>
                                        <div style="width: 100px; height: 3px; background-color: #000000; margin: 0 auto 7px auto; border-radius: 1px;"></div>
                                        <InputNumber v-model="consumptionMoney" :min="0" mode="currency" currency="BRL" :step="0.01"/>
                                    </div>
                                </div>
                            </div>
                            <div class="container-config-last">
                                <div class="container-shift">
                                    <div style="background-color: white; border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; height: 100%; color: #696969; text-align: center;
                                            display: flex; flex-direction: column; justify-content: center; padding: 0 20px;">
                                        <h4 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;" class="f-bold">
                                            TURNO
                                        </h4>
                                        <h5 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;">
                                            Filtre os dados por turno
                                        </h5>
                                        <div>
                                            <SelectButton v-model="showFilterShift" :options="showFilterShiftOptions" optionLabel="name" optionValue="value" multiple />
                                        </div>
    
                                        <div style="display: flex; flex-direction: column; margin: 20px auto 0 auto;" v-if="showFilterShiftA || showFilterShiftB || showFilterShiftC">
                                            <div style="display: flex;">
                                                <div style="display: flex; flex-direction: column;">
                                                    <label for="startHour">Inicio</label>
                                                    <Calendar id="startHour" ref="startHourCalendar" :timeOnly="true" :stepMinute="60" v-model="startHour"/>
                                                </div>
        
                                                <div style="display: flex; flex-direction: column; margin-left: 15px;">
                                                    <label for="endHour">Fim</label>
                                                    <Calendar id="endHour" :timeOnly="true" :stepMinute="60" v-model="endHour"/>
                                                </div>
                                            </div>

                                            <app-button type="secondary" v-if="showFilterShift[0] != showFilterShiftProp[showFilterShiftProp.length - 1]" style="width: 70px; margin: 20px auto 0 auto;"
                                                        @handleClick="filterHistoricalData">
                                                Filtrar
                                            </app-button>
                                            <app-button type="primary" v-else style="width: 70px; margin: 20px auto 0 auto;"
                                                        @handleClick="filterHistoricalData">
                                                Filtrado
                                            </app-button>
                                        </div>
                                    </div>
                                </div> 
                                <div class="container-format">
                                    <div style="background-color: white; border-radius: 10px; box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; height: 100%; text-align: center; align-items: center; justify-content: center; display: flex; flex-direction: column;">
                                        <h4 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;" class="f-bold">
                                            FORMATAÇÃO
                                        </h4>
                                        <h5 style="text-align: center; width: 100%; color: #696969; margin-bottom: 20px;">
                                            Escolha um formato para exibir os horários
                                        </h5>
                                        <SelectButton v-model="showFormatHour" :options="formatHourOptions" optionLabel="name" optionValue="value" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AppFilterBar from '../../common/AppFilterBar';
import AppButton from "../../common/AppButton";
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button/Button';
import InputNumber from 'primevue/inputnumber';
import AppWorkingModesLineChart from './AppWorkingModesLineChart';
import AppWorkingModesPie from './AppWorkingModesPie';
import AppWorkingModesInfoVehicle from './AppWorkingModesInfoVehicle.vue';
import exportJsonToCsv from '@/mixins/JsonToCsvMixin';
import VehiclesService from "../../../services/VehiclesService";
import dateHourFormat from "@/mixins/DateMixin";
import getTimeFromSecondsDurationFormatMixinShowZero from '../../../mixins/DateMixin';
import moment from 'moment';

export default {
    mixins: [getTimeFromSecondsDurationFormatMixinShowZero, exportJsonToCsv, dateHourFormat],
    mounted() {
        this.vehiclesService = new VehiclesService();
        this.showDialogFilters = this.isScreenMobile;
        this.is_col_12 = this.isScreenMobile;
        this.getValuesToPay();
    },

    data() {
        return {
            showFormatHour: true,
            showDialogFilters: false,
            is_col_12: false,
            displayMaximizable: false,
            vehiclesService: null,
            isLoadingSensorData: false,
            selectedOrganizations: [],
            selectedVehicles: [],
            startHour: new Date(1970, 0, 1, 0, 0, 0, 0),
            endHour: new Date(1970, 0, 1, 23, 59, 0, 0),
            tsStartHour: 0,
            tsEndHour: 0,
            showFilterShiftA: false,
            showFilterShiftB: false,
            showFilterShiftC: false,
            originalResponse: null,
            isFilterEndDateNextDay: false,
            workingModeMoney: 0,
            idleModeMoney: 0,
            maneuveringModeMoney: 0,
            travelingModeMoney: 0,
            offModeMoney: 0,
            waitingDataModeMoney: 0,
            maintenanceModeMoney: 0,
            consumptionMoney: 0,
            sumConsumption: {},
            SECONDS_IN_HOUR: 3600,
            arrayVehicles: [],
            DAY_IN_TIMESTAMP: 86400000,
            payload: {},
            vehicleSelected: 1,
            formatHourOptions: [
                {name: 'Decimal (0,00)', value: false},
                {name: 'Formatado (0h0m0s)', value: true}
            ],
            showFilterShift: [],
            showFilterShiftProp: '',
            showFilterShiftOptions: [
                { name: 'Turno A', value: 'A'},
                { name: 'Turno B', value: 'B'},
                { name: 'Turno C', value: 'C'}
            ]
        }
    },

    methods: {
        changeVehicleSelected(index){
            this.vehicleSelected = index
        },
        timeAndConsumption(time, consumption){
            let val1 = parseFloat(time.replace('.', '').replace(',', '.'));
            let val2 = parseFloat(consumption.replace('.', '').replace(',', '.'));
            return this.transformToLocaleString(val1 + val2)
        },
        showFormattedTime(val){
            if(this.showFormatHour){
                return this.getTimeFromSecondsDurationFormatMixinShowZero(val)
            }

            return this.showDecimalHours(val)
        },
        transformToLocaleString(val){
            return Number(val).toLocaleString('pt-BR');
        },
        showDecimalHours(val){
            return (val / 3600).toFixed(4).replace(".", ",")
        },
        showModePrices(val1, val2){
            let price = (val1 * (val2 / this.SECONDS_IN_HOUR)).toFixed(2);
            return Number(price).toLocaleString('pt-BR');
        },
        sumOfCosts(itemWorking, itemIdle, itemManeuvering, itemTraveling, itemOffMode, itemWaitingData, itemMaintenance){
            let price = ((this.workingModeMoney * (itemWorking / this.SECONDS_IN_HOUR)) +
                (this.idleModeMoney * (itemIdle / this.SECONDS_IN_HOUR)) +
                (this.maneuveringModeMoney * (itemManeuvering / this.SECONDS_IN_HOUR)) +
                (this.travelingModeMoney * (itemTraveling / this.SECONDS_IN_HOUR)) +
                (this.offModeMoney * (itemOffMode / this.SECONDS_IN_HOUR)) +
                (this.waitingDataModeMoney * (itemWaitingData / this.SECONDS_IN_HOUR)) +
                (this.maintenanceModeMoney * (itemMaintenance / this.SECONDS_IN_HOUR))).toFixed(2);
            return Number(price).toLocaleString('pt-BR');
        },
        exportCsv(){
            let arrayFilteredVehicles = this.arrayVehicles.filter(element => element.name != 'Total' && element.name != 'Config');
            let csvData = arrayFilteredVehicles.flatMap(vehicle => vehicle.dataToExportCSV);

            const CSV_FIELDS = ["name", "start", "time", "initial_hour_meter", "final_hour_meter", "consumption", "mode"];
            const CSV_LABELS = ["Maquina", "Inicio", "Duração", "Horímetro Inicial (h)", "Horímetro Final (h)", "Consumo (L)", "Modo"];

            this.exportJsonToCsv(CSV_FIELDS, CSV_LABELS, csvData, "Máquina");
        },
        validateDataPreRequest(dates, selectedOrganizations, selectedVehicles) {
            if (selectedVehicles.length === 0) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Selecione a organização',
                    detail: 'Selecione uma organização para continuar',
                    life: 5000
                });
                return;
            }

            let start = Date.parse(dates.start_date) / 1000;
            let end = (Date.parse(dates.end_date) / 1000) + 60;
            if((end - start) / 86400 > 31){
                this.$toast.add({
                    severity: 'error',
                    summary: 'Otimize a Pesquisa',
                    detail: 'Selecione um período de tempo mais curto',
                    life: 5000
                });
                return;
            }

            if (selectedVehicles.length > 10) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Quantidade de veículos',
                    detail: 'Selecione no máximo 10 veículos',
                    life: 5000
                });
                return;
            }

            this.displayMaximizable = false;
            this.selectedVehicles = selectedVehicles.sort((a, b) => {
                if (a.display_id < b.display_id) return -1;
                if (a.display_id > b.display_id) return 1;
                return 0;
            });

            this.payload = {
                start_date: moment.tz(dates.start_date, 'America/Sao_Paulo').utc().valueOf(),
                end_date: moment.tz(dates.end_date, 'America/Sao_Paulo').utc().valueOf(),
                ids: selectedVehicles.map(v => v.id)
            };

            this.getHistoricalData(this.payload);
        },
        getHistoricalData(payload) {
            this.isLoadingSensorData = true;
            let promisses = []
            payload.ids.forEach(element => {
                promisses.push(this.vehiclesService.getHistoricalDataDashboard(element, payload.start_date, payload.end_date));
            });
            
            // Faz as requisições em paralelo
            Promise.all(promisses).then((results) => {
                if (results.length != promisses.length) {
                    return
                }
                
                this.originalResponse = results;
                this.vehicleSelected = this.selectedVehicles.length > 1 ? 1 : 2

                // Aplica o filtro ao realizar uma pesquisa
                if(this.showFilterShiftA || this.showFilterShiftB || this.showFilterShiftC){
                    this.filterHistoricalData() 
                    return
                }

                this.setDataByOriginalResponse()
            }).catch(() => {
                this.arrayVehicles = []
                this.$toast.add({
                    severity: 'error',
                    summary: 'Operação falhou',
                    detail: 'Erro na busca de frotas em paralelo',
                    life: 5000
                });
            }).finally(() => {
                this.isLoadingSensorData = false;
            })
        },
        setDataByOriginalResponse(){
            this.arrayVehicles = []
            this.sumConsumption = {}
            this.originalResponse.forEach((result, index) => {
                if(result.length === 0){
                    return
                }

                let temp = {}
                temp.name = this.selectedVehicles[index].display_id
                temp.data = result
                this.setData(JSON.parse(JSON.stringify(temp)))
            })

            this.organizeArrayVehicles()
        },
        // Trata os dados e atribui ao this.arrayVehicles
        setData(response){
            let modeToColor = {
                1: '#2CA02C', // green - working
                2: '#D62728', // red - idle
                3: '#FFAA00', // orange - maneuvering
                4: '#1F76B4', // blue - traveling
                5: '#5E3F20', // brown - off
                6: '#FFFF33', // yellow - waiting
                7: '#797979', // gray - lost
                8: '#B507A7' // purple - maintenance
            }

            let timeByMode = {}
            let consumptionByMode = {}
            let dataToExportCSV = []
            let series = [{ type: 'line', data: [], markArea: { data: [] }, itemStyle: { color: '#505050' }}]
            response.data.sort((a, b) => a.event_start - b.event_start);
            response.data.forEach((data, i) => {
                let { mode, event_start, event_end, hour_meter_start, hour_meter_end } = data;

                // Limpa modos inexistentes
                if(mode === 0){
                    return
                }

                // Transforma dados em timestamp
                let start = event_start * 1000;
                let end = event_end * 1000;
                let duration = event_end - event_start;

                // Calcula o tempo e o consumo dos modos
                timeByMode[mode] = (timeByMode[mode] || 0) + duration;
                consumptionByMode[mode] = (consumptionByMode[mode] || 0) + data.consumption;

                // Extrai os pontos para criar o gráfico de linha
                let hourMeter = hour_meter_start < 1 ? null : hour_meter_start
                series[0].data.push([start, hourMeter]);

                // Caso o próximo ponto for distante é adicionado um ponto null
                if (response.data[i + 1] && response.data[i + 1].event_start !== event_end) {
                    hourMeter = hour_meter_end < 1 ? null : hour_meter_end
                    series[0].data.push([end, hourMeter], [end, null]);
                }

                // Caso o ultimo evento for o mesmo que o atual, eles se unem
                if(series[0].markArea.data.length > 0 && series[0].markArea.data[series[0].markArea.data.length - 1][0].itemStyle.color === modeToColor[mode] && 
                        series[0].markArea.data[series[0].markArea.data.length - 1][1].xAxis === start){
                    series[0].markArea.data[series[0].markArea.data.length - 1][1].xAxis = end
                } else{
                    series[0].markArea.data.push([
                        { xAxis: start, itemStyle: { color: modeToColor[mode], opacity: 0.7 } },
                        { xAxis: end }
                    ]);
                }

                if(hour_meter_start < 1 || hour_meter_end < 1){
                    return
                }

                // Cria o array para exportar CSV
                dataToExportCSV.push({
                    name: response.name,
                    start: this.dateHourFormat(start / 1000),
                    time: (duration / 3600).toFixed(4).replace(".", ","),
                    initial_hour_meter: hour_meter_start.toLocaleString('pt-BR'),
                    final_hour_meter: hour_meter_end.toLocaleString('pt-BR'),
                    consumption: data.consumption.toLocaleString('pt-BR'),
                    mode: ["", "Trabalhando", "Ocioso", "Manobrando", "Deslocando", "Desligado", "Aguardando Dados", "Não Monitorado", "Manutenção"][mode]
                })
            });

            // Adiciona ultimo ponto no gráfico de linha
            let lastData = response.data[response.data.length - 1];
            let hourMeter = lastData.hour_meter_end < 1 ? null : lastData.hour_meter_end
            series[0].data.push([lastData.event_end * 1000, hourMeter]);
            
            // Adiciona pontos nas meia noites
            if(!this.showFilterShiftA && !this.showFilterShiftB && !this.showFilterShiftC){
                let midnightPoints = this.getMidnightPoints()
                midnightPoints.forEach(element => {
                    series[0].data.push(element)
                });
            }

            // Atribui a soma dos modos as suas variaveis
            let workingConsumption = consumptionByMode[1] || 0;
            let idleConsumption = consumptionByMode[2] || 0;
            let maneuveringConsumption = consumptionByMode[3] || 0;
            let travelingConsumption = consumptionByMode[4] || 0;
            let maintenanceConsumption = consumptionByMode[8] || 0;
            let dataConsumption = workingConsumption + idleConsumption + maneuveringConsumption + travelingConsumption + maintenanceConsumption;

            // Atribui a soma dos modos as suas variaveis
            let workingSeconds = timeByMode[1] || 0;
            let idleSeconds = timeByMode[2] || 0;
            let maneuveringSeconds = timeByMode[3] || 0;
            let travelingSeconds = timeByMode[4] || 0;
            let offSeconds = timeByMode[5] || 0;
            let waitingSeconds = timeByMode[6] || 0;
            let lostSecods = timeByMode[7] || 0;
            let maintenanceSeconds = timeByMode[8] || 0;
            let dataSeconds = workingSeconds + idleSeconds + maneuveringSeconds + travelingSeconds + offSeconds + maintenanceSeconds;

            // Atribui os dados para os gráficos de pizza
            let dataToPieTime = this.setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSecods, maintenanceSeconds)
            let dataToPieConsumption = this.setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption)

            // Encontrar o primeiro e ultimo horímetro item não nulo
            let firstHourMeter;
            for (let i = 0; i < series[0].data.length; i++) {
                if (series[0].data[i][1] !== null && series[0].data[i][1] !== undefined) {
                    firstHourMeter = series[0].data[i][1].toLocaleString('pt-BR');
                    break;
                }
            }
            
            let lastHourMeter;
            for (let i = series[0].data.length - 1; i >= 0; i--) {
                if (series[0].data[i][1] !== null && series[0].data[i][1] !== undefined) {
                    lastHourMeter = series[0].data[i][1].toLocaleString('pt-BR');
                    break;
                }
            }

            let subTitleTemp = this.selectedVehicles.filter(element => element.display_id === response.name)[0]
            let subTitle = `${subTitleTemp.type} - ${subTitleTemp.manufacturer} ${subTitleTemp.model} - ${subTitleTemp.identification_number}`

            // Atribui os dados que os componentes receberão
            this.arrayVehicles.push({
                name: response.name,
                subTitle,
                workingConsumption,
                idleConsumption,
                maneuveringConsumption,
                travelingConsumption,
                dataConsumption,
                workingSeconds,
                idleSeconds,
                maneuveringSeconds,
                travelingSeconds,
                offSeconds,
                lostSecods,
                waitingSeconds,
                dataSeconds,
                maintenanceConsumption,
                maintenanceSeconds,
                firstHourMeter: firstHourMeter || 0,
                lastHourMeter: lastHourMeter || 0,
                dataToLine: series,
                dataToPieTime,
                dataToPieConsumption,
                dataToExportCSV
            })

            this.addConfigAndTotal(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, maintenanceSeconds, dataSeconds,
                workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, dataConsumption, lostSecods)
        },
        setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSeconds, maintenanceSeconds) {
            let data = [
                { name: "Não Monitorado", value: lostSeconds, itemStyle: {color: '#797979'} },
                { name: "Aguardando Dados", value: waitingSeconds, itemStyle: {color: '#FFFF33'} },
                { name: "Desligado", value: offSeconds, itemStyle: {color: '#5E3F20'} },
                { name: "Ocioso", value: idleSeconds, itemStyle: {color: '#D62728'} },
                { name: "Manutenção", value: maintenanceSeconds, itemStyle: {color: '#B507A7'} },
                { name: "Deslocando", value: travelingSeconds, itemStyle: {color: '#1F76B4'} },
                { name: "Manobrando", value: maneuveringSeconds, itemStyle: {color: '#FFAA00'} },
                { name: "Trabalhando", value: workingSeconds, itemStyle: {color: '#2CA02C'} }
            ]
            data = data.filter(element => element.value != 0)
            return data;
        },
        setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption) {
            let data = [
                { name: "Ocioso", value: idleConsumption, itemStyle: {color: '#D62728'} },
                { name: "Deslocando", value: travelingConsumption, itemStyle: {color: '#1F76B4'} },
                { name: "Manobrando", value: maneuveringConsumption, itemStyle: {color: '#FFAA00'} },
                { name: "Manutenção", value: maintenanceConsumption, itemStyle: {color: '#B507A7'} },
                { name: "Trabalhando", value: workingConsumption, itemStyle: {color: '#2CA02C'} }
            ]
            data = data.filter(element => element.value != 0)
            return data;
        },
        getFilteredTime(){
            if(!this.showFilterShiftA && !this.showFilterShiftB && !this.showFilterShiftC){
                return this.payload.end_date - this.payload.start_date;
            }

            let startDateFilter = new Date(this.startHour).getTime();
            let endDateFilter = new Date(this.endHour).getTime();

            if(startDateFilter >= endDateFilter){
                endDateFilter = new Date(this.endHour).getTime() + this.DAY_IN_TIMESTAMP;
            }

            return (endDateFilter - startDateFilter) * (this.payload.end_date - this.payload.start_date) / this.DAY_IN_TIMESTAMP;
        },
        getMidnightPoints(){
            let currentDay = new Date(this.payload.start_date);
            let endDate = new Date(this.payload.end_date);
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let midnightPoints = []
            while (currentDay <= endDate) {
                let midnightTimestamp = new Date(currentDay);
                midnightTimestamp.setHours(0, 0, 0, 0);

                midnightPoints.push([midnightTimestamp.getTime(), null]);
                
                if (currentDay.getDate() === today.getDate() && currentDay.getMonth() === today.getMonth() && currentDay.getFullYear() === today.getFullYear()) {
                    let currentTime = new Date();
                    midnightPoints.push([currentTime.getTime(), null]);
                }

                currentDay.setDate(currentDay.getDate() + 1);
            }

            if (!(endDate.getDate() === today.getDate() && endDate.getMonth() === today.getMonth() && endDate.getFullYear() === today.getFullYear())) {
                endDate.setHours(23, 59, 59, 999);
                midnightPoints.push([endDate.getTime() + 1000, null]);
            }
            return midnightPoints
        },
        // Adiciona as abas config e total
        addConfigAndTotal(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, maintenanceSeconds, dataSeconds,
            workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption, maintenanceConsumption, dataConsumption, lostSecods) {
            if (this.arrayVehicles[1] && this.arrayVehicles[1].name === "Total") {
                this.arrayVehicles[1].workingConsumption += workingConsumption
                this.arrayVehicles[1].idleConsumption += idleConsumption
                this.arrayVehicles[1].maneuveringConsumption += maneuveringConsumption
                this.arrayVehicles[1].travelingConsumption += travelingConsumption
                this.arrayVehicles[1].dataConsumption += dataConsumption
                this.arrayVehicles[1].workingSeconds += workingSeconds
                this.arrayVehicles[1].idleSeconds += idleSeconds
                this.arrayVehicles[1].maneuveringSeconds += maneuveringSeconds
                this.arrayVehicles[1].travelingSeconds += travelingSeconds
                this.arrayVehicles[1].offSeconds += offSeconds
                this.arrayVehicles[1].lostSecods += lostSecods
                this.arrayVehicles[1].waitingSeconds += waitingSeconds
                this.arrayVehicles[1].dataSeconds += dataSeconds
                this.arrayVehicles[1].maintenanceConsumption += maintenanceConsumption
                this.arrayVehicles[1].maintenanceSeconds += maintenanceSeconds
                this.arrayVehicles[1].dataToPieTime = this.setDataToPieTime(this.arrayVehicles[1].workingSeconds, this.arrayVehicles[1].idleSeconds, this.arrayVehicles[1].maneuveringSeconds, this.arrayVehicles[1].travelingSeconds, this.arrayVehicles[1].offSeconds, this.arrayVehicles[1].waitingSeconds, this.arrayVehicles[1].lostSecods)
                this.arrayVehicles[1].dataToPieConsumption = this.setDataToPieConsumption(this.arrayVehicles[1].workingConsumption, this.arrayVehicles[1].idleConsumption, this.arrayVehicles[1].maneuveringConsumption, this.arrayVehicles[1].travelingConsumption)

                let filteredTime = this.getFilteredTime();
                let percent = ((this.arrayVehicles[1].dataSeconds * 1000) / filteredTime * 100 / this.selectedVehicles.length).toFixed(2)
                this.arrayVehicles[1].totalPercent = Number(percent).toLocaleString('pt-BR')
            } else {
                let filteredTime = this.getFilteredTime();
                let percent = ((dataSeconds * 1000) / filteredTime * 100 / this.selectedVehicles.length).toFixed(2)
                this.arrayVehicles.unshift({
                    name: "Total",
                    workingConsumption,
                    idleConsumption,
                    maneuveringConsumption,
                    travelingConsumption,
                    dataConsumption,
                    workingSeconds,
                    idleSeconds,
                    maneuveringSeconds,
                    travelingSeconds,
                    offSeconds,
                    lostSecods,
                    waitingSeconds,
                    dataSeconds,
                    maintenanceConsumption,
                    maintenanceSeconds,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [],
                    dataToPieTime: this.setDataToPieTime(workingSeconds, idleSeconds, maneuveringSeconds, travelingSeconds, offSeconds, waitingSeconds, lostSecods),
                    dataToPieConsumption: this.setDataToPieConsumption(workingConsumption, idleConsumption, maneuveringConsumption, travelingConsumption),
                    dataToExportCSV: [],
                    totalPercent: Number(percent).toLocaleString('pt-BR')
                })
            }

            if (this.arrayVehicles[0].name != "Config") {
                this.arrayVehicles.unshift({
                    name: "Config",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }
        },
        // Adiciona gráficos vazios referentes ao veiculos sem dados
        organizeArrayVehicles(){
            let newArray = this.selectedVehicles.filter(selectedVehicle => {
                return !this.arrayVehicles.some(vehicle => vehicle.name === selectedVehicle.display_id);
            }).map(selectedVehicle => selectedVehicle.display_id);

            newArray.forEach(element => {
                let subTitleTemp = this.selectedVehicles.filter(elementSelected => elementSelected.display_id === element)[0]
                let subTitle = `${subTitleTemp.type} - ${subTitleTemp.manufacturer} ${subTitleTemp.model} - ${subTitleTemp.identification_number}`

                this.arrayVehicles.push({
                    name: element,
                    subTitle,
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            });

            if(this.arrayVehicles[1] && this.arrayVehicles[1].name != 'Total'){
                this.arrayVehicles.unshift({
                    name: "Total",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }

            if(this.arrayVehicles[0].name != 'Config'){
                this.arrayVehicles.unshift({
                    name: "Config",
                    workingSeconds: 0,
                    idleSeconds: 0,
                    maneuveringSeconds: 0,
                    travelingSeconds: 0,
                    offSeconds: 0,
                    lostSecods: 0,
                    waitingSeconds: 0,
                    maintenanceConsumption: 0,
                    maintenanceSeconds: 0,
                    dataSeconds: 0,
                    firstHourMeter: 0,
                    lastHourMeter: 0,
                    dataToLine: [{ type: 'line', data: [], markArea: { data: [] } }],
                    dataToPie: [],
                    dataToExportCSV: {}
                })
            }
        },
        // Filtra o this.originalResponse por turno
        filterHistoricalData(){
            this.showFilterShiftProp = this.showFilterShiftA ? 'TURNO A' : this.showFilterShiftB ? 'TURNO B' : 'TURNO C'
            this.isFilterEndDateNextDay = this.tsStartHour > this.tsEndHour || this.tsStartHour === this.tsEndHour

            this.arrayVehicles = []
            this.sumConsumption = {}
            this.originalResponse.forEach((result, index) => {
                let tempData = []
                result.forEach(element => {
                    let eventStart = new Date(element.event_start * 1000);
                    let eventEnd = new Date(element.event_end * 1000);

                    if (this.isEventWithinTimeRange(eventStart, eventEnd)) {
                        tempData.push(element)
                    }
                });

                if(tempData.length === 0){
                    return
                }

                let temp = {}
                temp.name = this.selectedVehicles[index].display_id
                temp.data = tempData
                this.setData(JSON.parse(JSON.stringify(temp)))
            })

            this.organizeArrayVehicles()
        },
        isEventWithinTimeRange(start, end) {
            let startEvent = start.getHours() * 3600 + start.getMinutes() * 60 + start.getSeconds();
            let endEvent = end.getHours() * 3600 + end.getMinutes() * 60 + end.getSeconds();
            
            let startFilter = this.tsStartHour * 3600;
            let endFilter = this.tsEndHour * 3600;

            if (this.isFilterEndDateNextDay) {
                return startEvent >= startFilter || endEvent <= endFilter;
            }

            return (startEvent >= startFilter && startEvent <= endFilter) && 
                (endEvent >= startFilter && endEvent <= endFilter);
        },
        // Recebe valores por modo de trabalho do LocalStorage
        getValuesToPay(){
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));

            if (temp === null || temp === undefined || typeof temp.shift_a_start === 'undefined' || 
            typeof temp.shift_a_end === 'undefined' || typeof temp.shift_b_start === 'undefined' || 
            typeof temp.shift_b_end === 'undefined' || typeof temp.shift_c_start === 'undefined' || 
            typeof temp.shift_c_end === 'undefined' || !Array.isArray(temp.price_of_modes) || 
            temp.price_of_modes.length !== 8){
                let localStorageDefault = {
                    shift_a_start: 0,
                    shift_a_end: 0,
                    shift_b_start: 0,
                    shift_b_end: 0,
                    shift_c_start: 0,
                    shift_c_end: 0,
                    price_of_modes: [0, 0, 0, 0, 0, 0, 0, 0]
                }
                localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(localStorageDefault));
                return
            }

            this.workingModeMoney = temp.price_of_modes[0] === undefined || temp.price_of_modes[0] === null ? 0 : temp.price_of_modes[0]
            this.idleModeMoney = temp.price_of_modes[1] === undefined || temp.price_of_modes[1] === null ? 0 : temp.price_of_modes[1]
            this.maneuveringModeMoney = temp.price_of_modes[2] === undefined || temp.price_of_modes[2] === null ? 0 : temp.price_of_modes[2]
            this.travelingModeMoney = temp.price_of_modes[3] === undefined || temp.price_of_modes[3] === null ? 0 : temp.price_of_modes[3]
            this.offModeMoney =  temp.price_of_modes[4] === undefined || temp.price_of_modes[4] === null ? 0 : temp.price_of_modes[4]
            this.waitingDataModeMoney = temp.price_of_modes[5] === undefined || temp.price_of_modes[5] === null ? 0 : temp.price_of_modes[5]
            this.maintenanceModeMoney = temp.price_of_modes[6] === undefined || temp.price_of_modes[6] === null ? 0 : temp.price_of_modes[6]
            this.consumptionMoney = temp.price_of_modes[7] === undefined || temp.price_of_modes[7] === null ? 0 : temp.price_of_modes[7]
        },
        // Seta valores por modo de trabalho no LocalStorage
        setValuesToPay(){
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));

            temp.price_of_modes[0] = this.workingModeMoney
            temp.price_of_modes[1] = this.idleModeMoney
            temp.price_of_modes[2] = this.maneuveringModeMoney
            temp.price_of_modes[3] = this.travelingModeMoney
            temp.price_of_modes[4] = this.offModeMoney
            temp.price_of_modes[5] = this.waitingDataModeMoney
            temp.price_of_modes[6] = this.maintenanceModeMoney
            temp.price_of_modes[7] = this.consumptionMoney
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(temp));
        },
        selectedFilterShift(letter){
            if(letter === 'a') {
                this.showFilterShiftB = false
                this.showFilterShiftC = false;
            }
            if(letter === 'b') {
                this.showFilterShiftA = false
                this.showFilterShiftC = false;
            }
            if(letter === 'c') {
                this.showFilterShiftB = false
                this.showFilterShiftA = false;
            }

            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));
            this.startHour = new Date(1970, 0, 1, temp[`shift_${letter}_start`], 0, 0, 0)
            this.endHour = new Date(1970, 0, 1, temp[`shift_${letter}_end`], 0, 0, 0)
        },
        updateLocalStorage(shift, val) {
            let temp = JSON.parse(localStorage.getItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES));
            temp[shift] = val;
            localStorage.setItem(process.env.VUE_APP_LOCAL_STORAGE_WORKING_MODES_VALUES, JSON.stringify(temp));
        },
        restoreData(){
            if(this.showFilterShiftA || this.showFilterShiftB || this.showFilterShiftC){
                return
            }

            this.setDataByOriginalResponse()
        }
    },
    computed: {
        isScreenMobile: function() {
            return window.screen.width <= 1024;
        },
        arrayVehiclesName: function() {
            return this.arrayVehicles.map(element => element.name);
        },
        getItemByArrayVehicles: function(){
            return this.arrayVehicles[this.vehicleSelected];
        }
    },
    watch:{
        startHour: function(val){
            let date = new Date(val);
            this.tsStartHour = date.getHours();

            if (this.showFilterShiftA) {
                this.updateLocalStorage('shift_a_start', this.tsStartHour);
                return
            }
            if (this.showFilterShiftB) {
                this.updateLocalStorage('shift_b_start', this.tsStartHour);
                return
            }
            if (this.showFilterShiftC) {
                this.updateLocalStorage('shift_c_start', this.tsStartHour);
                return
            }
        },
        endHour: function(val){
            let date = new Date(val);
            this.tsEndHour = date.getHours();

            if (this.showFilterShiftA) {
                this.updateLocalStorage('shift_a_end', this.tsEndHour);
                return
            }
            if (this.showFilterShiftB) {
                this.updateLocalStorage('shift_b_end', this.tsEndHour);
                return
            }
            if (this.showFilterShiftC) {
                this.updateLocalStorage('shift_c_end', this.tsEndHour);
                return
            }
        },
        showFilterShiftA: function(val){
            if(val) {
                this.selectedFilterShift("a");
            }
            this.restoreData();
        },
        showFilterShiftB: function(val){
            if(val) {
                this.selectedFilterShift("b");
            }
            this.restoreData();
        },
        showFilterShiftC: function(val){
            if(val) {
                this.selectedFilterShift("c");
            }
            this.restoreData();
        },
        workingModeMoney: function(){
            this.setValuesToPay()
        },
        idleModeMoney: function(){
            this.setValuesToPay()
        },
        maneuveringModeMoney: function(){
            this.setValuesToPay()
        },
        travelingModeMoney: function(){
            this.setValuesToPay()
        },
        offModeMoney: function(){
            this.setValuesToPay()
        },
        waitingDataModeMoney: function(){
            this.setValuesToPay()
        },
        maintenanceModeMoney: function(){
            this.setValuesToPay()
        },
        consumptionMoney: function(){
            this.setValuesToPay()
        },
        showFilterShift: function(val){
            if(val.length === 0){
                this.showFilterShiftA = false
                this.showFilterShiftB = false
                this.showFilterShiftC = false
                this.showFilterShiftProp = ''
                return
            }

            if(val.length > 1){
                this.showFilterShift.shift()
            }

            if(this.showFilterShift[0] === "A"){
                this.showFilterShiftA = true
            }

            if(this.showFilterShift[0] === "B"){
                this.showFilterShiftB = true
            }

            if(this.showFilterShift[0] === "C"){
                this.showFilterShiftC = true
            }
        }
    },
    components: {
        Dialog,
        AppButton,
        AppFilterBar,
        AppWorkingModesLineChart,
        AppWorkingModesPie,
        AppWorkingModesInfoVehicle,
        ProgressSpinner,
        Calendar,
        Checkbox,
        Button,
        InputNumber,
        SelectButton
    },
}
</script>

<style scoped lang="scss">

.container-config {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-config-first {
    padding: 0 0 5px 0;
    width: 90%;
    height: 47.5%;
}

.container-config-last {
    display: flex;
    height: 47.5%;
    width: 90%;
}

.container-costs {
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100%;
    display: flex;
    padding: 10px 10%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.container-shift {
    padding: 5px 5px 0 0;
    width: 50%;
}

.container-format {
    padding: 5px 0 0 5px;
    width: 50%;
}

.height-info, .height-info-total, .height-info-config{
    width: calc(100% - 80px); 
    height: 740px;
    padding: 20px; 
    margin: 0 0 0 40px; 
    border: 2px solid rgb(180, 180, 180);
    border-top-right-radius: 20px; 
    border-bottom-left-radius: 20px; 
    border-bottom-right-radius: 20px; 
    position: relative; 
    bottom: 2px;
}

.icon-RS {
    height: 30px;
    width: 30px;
    margin: 0 7px;
    border-radius: 50%;
    background-color: #F3AD26;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: white;
    border: 1px solid #FEE440;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    bottom: 3px;
    padding-bottom: 1px;
}

.dashboard-container {
    height: 785px;
    width: 98%;
    padding: 20px;
    margin: auto;
    border: 2px solid rgb(180, 180, 180);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.width-vehicle-name{
    width: calc(100vw - 250px);
    margin: 40px 0 0 40px;
    display: flex;
    border-top-right-radius: 11px;
    border-top-left-radius: 11px;
    overflow-x: auto; 
    white-space: nowrap;
}

.vehicle-name{
    border: 2px solid rgb(180, 180, 180);
    border-bottom: none;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 0 20px;
    height: 45px;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: #F4F4F4;
    background-color: rgb(180, 180, 180);
    cursor: pointer;
}

.vehicle-name h4{
    position: relative;
    top: 2px;
    font-size: 17px;
}

.vehicle-name-active{
    border: 2px solid rgb(180, 180, 180);
    border-bottom: none;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    padding: 0 20px;
    height: 45px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    color: #696969;
}

.vehicle-name-active h4{
    position: relative;
    top: 2px;
    font-size: 17px;
}

.f-bold{
    font-weight: bold;
}

.m-t-17{
    margin-top: 17px;
}

.m-t-13{
    margin-top: 13px;
}

.no-data-container{
    background-color: white;
    color: #696969;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-data-container-after-filter{
    background-color: white;
    color: #696969;
    width: 80%;
    margin: 20px auto 20px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dashboard-container-no-data {
    width: 90%;
    margin: 30px auto 0 auto;
}

.dashboard-container-loading{
    height: calc(100vh - 81px);
    width: 80%;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.input-money{
    width: 22%;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.container-input-money{
    height: 18%;
    padding-top: 11px;
    display: flex;
    flex-wrap: wrap;
}

h5{
    font-size: 15px
}

.menu-container{
    height: 250px;
    width: 90%;
    margin: 0 auto 30px auto;
}

.width-selected-shift{
    width: 33%;
    margin: 20px 0 0 11px;
}

.width-selected-hour{
    width: 33%;
    margin: 0 auto;
}

.width-export-csv{
    width: 33%;
    margin: auto;
}

.menu-shift{
    display: flex;
    height: 50%;
    color: #696969;
}

.button-filter-shift{
    font-size: 13px;
    float: right;
    margin-right: 11px;
    height: 35px;
}

.justify-selected-hour{
    display: flex
}

@media (min-width: 1399px) and (max-width: 1635px) {
    h6{
        font-size: 11px
    }

    h5{
        font-size: 13px
    }

    h4{
        font-size: 18px;
    }
}

@media (min-width: 1025px) and (max-width: 1398px) {
    .height-info{
        height: 930px;
        margin-bottom: 40px;
    }

    .height-info-total{
        height: 890px;
        margin-bottom: 40px;
    }

    h6{
        font-size: 11px
    }

    h5{
        font-size: 13px
    }

    h4{
        font-size: 18px;
    }
}

@media (max-width: 1024px){

    .width-vehicle-name{
        width: calc(95vw - 30px) !important;
        margin: 15px 0 0 2.5vw;
    }

    .height-info{
        height: 1690px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-total{
        height: 1220px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .menu-container{
        width: 95%;
        height: auto;
        margin-bottom: 10px;
    }

    .menu-shift{
        display: block;
        text-align: center;
        height: auto;
    }

    .width-selected-shift{
        margin: 0;
        width: 100%;
    }

    .width-selected-hour{
        margin: 20px auto 0 auto;
        width: 100%;
    }

    .justify-selected-hour{
        justify-content: center;
    }

    .width-export-csv{
        margin: 20px 0 0 0;
        padding-bottom: 50px;
        width: 100%;
        height: 35px;
    }

    .button-filter-shift{
        float: none;
        margin: 0;
    }

    h5{
        font-size: 14px
    }

    .input-money{
        width: 27%;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .input-money > div:first-of-type {
        width: 20px !important; 
        height: 3px;
    }

    .container-input-money{
        height: 18%;
        display: flex;
        flex-wrap: wrap;
    }
        
    .width-vehicle-name{
        width: 95%;
    }

    .container-config {
        height: auto;
    }

    .container-config-first {
        width: 100%;
        height: 350px;
    }

    .container-config-last {
        display: block;
        height: 600px;
        width: 100%;
    }

    .container-costs {
        background-color: white;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
        height: 100%;
        display: flex;
        padding: 0px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .container-shift {
        padding: 5px 0;
        height: 300px;
        width: 100%;
    }

    .container-format {
        padding: 5px 0;
        height: 200px;
        width: 100%;
    }
    
}

@media (max-width: 500px) {

    .height-info{
        height: 1880px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

    .height-info-total{
        height: 1280px;
        width: 95vw;
        margin: 0 auto;
        padding: 10px;
    }

}

</style>
